import { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import plusIcon from '../../image/icon/plus_ic.svg';
import phoneIc from '../../image/icon/phone-solid_green_ic.svg';
import mailIc from '../../image/icon/email-solid_green_ic.svg';
import globeIc from '@ucrecruits/globalstyle/images/icons/globe.svg';
import axios from 'axios';
import { getEnv } from "@urecruits/api";
import { Skeleton, Stack } from "@mui/material";
import noData from '../../image/no-data.svg';

const {API_ASSESSMENT,API_RECRUITMENT} = getEnv();
const token: string = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  };


const CompanyLinks = ({position}) => {
	const [data,setData] = useState<any>()
	const [isLoading,setIsLoading] = useState(true)
	const [isEmptyData,setIsEmptyData] = useState(false)

	useEffect(()=>{
		async function getInfo(){
			await axios.get(`${API_RECRUITMENT}/api/company`,config).then(data=>{
                if(!data.data){
                    setIsEmptyData(true)
                }else{
                    setData(data.data)
                }
			})
			setIsLoading(false)
		}

		try{
			getInfo();
		}catch(er)
		{console.log(er)}
	},[])

	return (
		<div className={`dashboard-list ${position}`}>
			<div className="dashboard-list__head">
				<p className="dashboard-list__head__headline">About</p>
			</div>
			{
				isLoading && <Stack height={"100%"}>
                    <Skeleton animation="wave" />
                </Stack>
			}
            {isEmptyData ? <img src={noData} alt="Empty Data"  className="dashboard-list__empty"/>
				:
				data && <>
                    <div className="dashboard-dropdown__body">
                        <div className="dashboard-dropdown__headline">
                            <p>{data.name}</p>
                            {
                                data.about && 
                            <p dangerouslySetInnerHTML={{__html:`${data.about.substring(0,150)}...`}} className="dashboard-dropdown__headline__about"></p>
                            }
                        </div>
                        <div className="company-links">
                            {
                                data.phone && 
                                <Link to="" className="company-links__item">
                                    <img src={phoneIc} alt="phone ic"/>
                                    {data.phone}
                                </Link>
                            }
                            {
                                data.email && 
                                <Link to="/recruitment/mail-box" className="company-links__item">
                                    <img src={mailIc} alt="phone ic"/>
                                    {data.email}
                                </Link>
                            }
                            {
                                data.website && 
                                <a href={data.website} className="company-links__item">
                                    <img src={globeIc} alt="phone ic"/>
                                    {data.website}
                                </a>
                            }
                            {
                                !!data.address?.length && (data.address[0].street || data.address[0].country) &&
                            <p className="company-links__item">
                                <img src={plusIcon} alt="phone ic"/>
                                {data.address[0].street+","+data.address[0].country}
                            </p>
                            }
                        </div>
                    </div>               
                </>
			}
		</div>
	);
};


export default memo(CompanyLinks);

const ShowListItem = ({offer})=>{

	const navigate = useNavigate()
	const date = new Date(offer.createdAt).toString()

	return <div className="dashboard-list__item" >
	<div className="dashboard-list__item__inner">
        <div className="dashboard-list__item__top">
            <p className="dashboard-list__item__username">{offer.title}</p>
        </div>
		<div className="dashboard-list__item__bottom">
			{/* {roundData && 
                <p className="dashboard-list__item__dot">{roundData.title}</p>
            } */}
			<p className="dashboard-list__item__dot">Contains {offer.workflow?.length+"steps" || ""}</p>
			<p className="dashboard-list__item__text">{date.substring(4,10)+","+date.substring(11,15)|| ""}</p>
		</div>
	</div>
	<div className="dashboard-list__item__arrow" onClick={()=>navigate("/recruitment/position-workflow")}>
		<ArrowRight/>
	</div>
</div>
}

const ArrowRight = memo(() => {

	return (
		<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M3 1L7 5L3 9" stroke="#B0BBCB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
});