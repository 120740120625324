import { memo, useEffect, useState } from "react";
import SingleItem, { ButtonItem } from "../Global/table/SingleMobileItem";
import { DomainAssessmentTabs } from "../../enums/domain-assessment/domain-assessment";
import { getEnv } from "@urecruits/api";

import edit from "../../image/icon/edit_ic.svg";
import bin from "../../image/icon/delete_ic.svg";
import plus from "../../image/icon/plus_ic.svg";
import { store } from "../../store";
import { setOpenPreview, setPopupWithDelete, setPopupWithInstruction } from "../../store/reducers/domainAssessmentReducer";
import { useNavigate } from "react-router-dom";
import fetchData from "../../hook/http";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT } = getEnv();

const ManageDomainMobileTable = ({ item }) => {
  const [openItem, setOpenItem] = useState(false);
  const [industryData, setIndustryData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    fetchData(`${API_RECRUITMENT}/api/industry/label/`).then((data) => {
      if (data) {
        setIndustryData(data);
      }
    });

    fetchData(`${API_RECRUITMENT}/api/department/label/`).then((data) => {
      if (data) {
        setDepartmentData(data);
      }
    });
  }, []);

  const handleIndustry = (item) => {
    const label =
      industryData.length > 0 &&
      industryData.find((info) => info.id === item)?.label;
    return label;
  };

  const handleDepartment = (item) => {
    const label =
      departmentData.length > 0 &&
      departmentData.find((info) => info.id === item)?.label;
    return label;
  };

  return (
    <div className="table-mobile__item">
      <div
        className={`table-mobile__top ${openItem ? "active" : ""}`}
        onClick={() => setOpenItem(prev => !prev)}
      >
        <div className="table-mobile__top__left">
          <p className="table-mobile__top__name">{`${item?.assessmentName}`}</p>
        </div>
        <ArrowSvg />
      </div>
      <div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
        <ButtonItem title="Assessment Name"
          text={
            <p onClick={() => store.dispatch(setOpenPreview({ isOpen: true, id: item?.id }))}>{item?.assessmentName}</p>
          }
          textStyle="table-mobile__item__text table-mobile__item__text--green"
        />
        <SingleItem
          title={DomainAssessmentTabs.Industry + ":"}
          text={handleIndustry(item?.industryId)}
          textStyle={"table-mobile__item__text"}
        />
        <SingleItem
          title={DomainAssessmentTabs.Department + ":"}
          text={handleDepartment(item?.department)}
          textStyle={"table-mobile__item__text"}
        />
        <SingleItem
          title={DomainAssessmentTabs.Time + ":"}
          text={item?.timeDuration}
          textStyle={"table-mobile__item__text"}
        />
        <SingleItem
          title={DomainAssessmentTabs.Score + ":"}
          text={item.score}
          textStyle={"table-mobile__item__text"}
        />
        <ButtonItem
          title={DomainAssessmentTabs.Instructions + ":"}
          text={
            item.instruction ? (
              <button
                className="manage-team__text manage-team__text--green manage-team__text__button"
                onClick={() => {
                  store.dispatch(
                    setPopupWithInstruction({
                      open: true,
                      id: item.id,
                      instruction: item.instruction || null,
                      title: item.assessmentName,
                    })
                  );
                }}>
                See Instruction
              </button>
            ) : (
              <>
              <AuthGuard module='assessment' permission='edit'>
                <button
                  className="manage-team__text--green manage-team__text"
                  onClick={() => {
                    store.dispatch(
                      setPopupWithInstruction({
                        open: true,
                        id: item.id,
                        instruction: "",
                        title: item.assessmentName,
                      })
                    );
                  }}>
                  <img
                    src={plus}
                    alt="Edit-icon"
                    className="manageAssignment__table__icon"
                  />
                  Add Instruction
                </button>
              </AuthGuard>
              </>
            )
          }
          textStyle={"table-mobile__item__text table-mobile__item__text--green"}
        />
        <div className="table-mobile__item__body__action" key={item.displayName}>
          <AuthGuard module='assessment' permission='edit'>
            <button className='table-mobile__item__action-button table-mobile__item__action-button--half' onClick={(e) => {
              navigate(`/manage-domain/edit/${item.id}`)
            }}>
              <img src={edit} alt="delete-icon" />
              Edit
            </button>
          </AuthGuard>
          <AuthGuard module='assessment' permission='delete'>
            <button className='table-mobile__item__action-button table-mobile__item__action-button--half' onClick={() => {
              store.dispatch(setPopupWithDelete({
                open: true,
                id: item.id,
                title: item.assessmentName
              }))
            }}>
              <img src={bin} alt="delete-icon" />
              Delete
            </button>
          </AuthGuard>
        </div>
      </div>
    </div>
  );
};
export default memo(ManageDomainMobileTable);
const ArrowSvg = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6751_20970)">
        <path
          d="M1 3L5 7L9 3"
          stroke="#5F6E80"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6751_20970">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(0 10) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
