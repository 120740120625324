import {createSlice} from '@reduxjs/toolkit'
// eslint-disable-next-line no-duplicate-imports
import type {PayloadAction} from '@reduxjs/toolkit'
import {WorkflowTabs} from "../../enums/workflow/WorkFlowEnums";
import {IPositionWorkflow} from "../../types/redux/position-workflow";

export const defaultWorkflowFilters = {
	searchValue: '',
	sortBy: 'id',
	sortType: 'DESC',
}


const initialState: IPositionWorkflow = {
	emptySearch: false,
	assignPopup:false,
	emptyTable: false,
	filters: defaultWorkflowFilters,
	tableItems: [],
	fixedTab: {
		id: 1,
		displayName: WorkflowTabs.Title,
	},
	popupWithList:{
		title:'',
		open:false,
		id:-1,
		workflows:[]
	},
	tabFilter: [
		{
			id: 2,
			displayName: WorkflowTabs.Workflow,
			active: true,
			dbName: ''
		},
		{
			id: 3,
			displayName: WorkflowTabs.CreatedBy,
			active: true,
			dbName: ''
		},
		{
			id: 4,
			displayName: WorkflowTabs.CreatedOn,
			active: true,
			dbName: 'createdAt'
		},
		{
			id: 5,
			displayName: WorkflowTabs.Actions,
			active: true,
			dbName: ''
		},
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0
	},
	deletePopup:{
		open:false,
		id:-1,
		title:''
	}
}

export const pw = createSlice({
	name: 'pw',
	initialState,
	reducers: {
		setDeletePopupWorkflow:(state, action: PayloadAction<{ open:boolean, id:number, title:string }>) => {
			state.deletePopup = action.payload
		},
		setAssignPopupWorkflow:(state, action: PayloadAction<boolean>) => {
			state.assignPopup = action.payload
		},
		setSortTypeFilterWorkflow: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload
		},
		setSortByFilterWorkflow: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload
		},
		setSearchFilterWorkflow: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setLimitWorkflow: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload,
				state.pagination.currentPage = 1
		},
		setCurrentPageWorkflow: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload
		},
		setTotalCountWorkflow: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload
		},
		setItemsWorkflow: (state, action: PayloadAction<any>) => {
			state.tableItems = action.payload
		},
		setTableEmptyWorkflow: (state, action: PayloadAction<boolean>) => {
			state.emptyTable = action.payload
		},
		setNoItemsSearchWorkflow: (state, action: PayloadAction<boolean>) => {
			state.emptySearch = action.payload
		},
		setPopupWithListWorkflow: (state, action: PayloadAction<{title:string, open:boolean, id:number, workflows:Array<any>}>) => {
			state.popupWithList = action.payload
		},
	},
})


export const {
	setDeletePopupWorkflow,
	setAssignPopupWorkflow,
	setPopupWithListWorkflow,
	setTableEmptyWorkflow,
	setNoItemsSearchWorkflow,
	setItemsWorkflow,
	setTotalCountWorkflow,
	setCurrentPageWorkflow,
	setSortByFilterWorkflow,
	setSortTypeFilterWorkflow,
	setSearchFilterWorkflow,
	setLimitWorkflow

} = pw.actions

export default pw.reducer