import { memo, useEffect, useRef, useState } from "react";
import { getEnv } from "@urecruits/api";
import { store, useTypedSelector } from "../../store";
import { setCalendarPopupCandidateScoreboard, setScheduleMettingPopupScoreboard } from "../../store/reducers/candidateScoreboardScreenReducer";
import useClickOutside from "../../hook/useClickOutside";
import { default as ReactSelect, components } from "react-select";
import AsyncSelect from "react-select/async";
import { selectCustomStyle } from "../../styles/selectCustomStyle";
import fetchData, { patchData } from "../../hook/http";
import { postData } from "../../hook/http";
import { EditorModals } from "../../components/Popups/editor-modals";


const { API_RECRUITMENT } = getEnv();
const date = (state) => state.candidate_scoreboard.calendarPopup;

const timeOptions = [
  "15 minutes",
  "30 minutes",
  "45 minutes",
  "60 minutes",
].map((item) => ({ label: item, value: item }));
const ScheduleMeeting = ({
  meetingData,
  setIsScheduleMeeting,
  postApi=`${API_RECRUITMENT}/api/calendar/book-appointment`
}) => {
  const [errorNotification, setErrorNotification] = useState("");
  const [meetingTitle, setMeetingTitle] = useState(
    meetingData.eventTitle || ""
  );
  const [meetingTitleError, setMeetingTitleError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const { open: openCalendar, startDate, endDate } = useTypedSelector(date);
  const popupRef = useRef();
  const [interviewers, setInterviewers] = useState(
    getInterviewerOptions(meetingData.interviewers)
  );
  const [remindMeTime, setRemindMeType] = useState(null);
  const [modal, setModal] = useState<any>({
    state: false,
    data: null,
  });

  const openSuccessModal = () => {
    setModal({
      state: "success",
      data: {
        title: "Successfully completed",
        description:
          "You have successfully scheduled a meeting. The event will appear in your calendar, as well as a notification of participation in the meeting was send to the mail of all participants.",
      },
    });
    setIsScheduleMeeting(false)
  };

  const closeSuccessModal = (arg)=>{
  if(arg===false)
   setModal({
    state:false,
    data:null
   })
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const { formattedDate, formattedTime } = formatDateTime(startDate);

    const body = {
      date: formattedDate,
      slot: formattedTime,
      eventTitle: meetingTitle,
      remindMe: remindMeTime ? true : false,
      jobId: meetingData.jobId,
      candidateId: meetingData.candidateId,
      interviewers: interviewers?.map((item) => item.value),
      timeBefore: remindMeTime,
      recruiterId: meetingData.recruiterId,
      description: meetingData.roundName,
    };

    if (meetingData.eventId) {
      body["eventId"] = meetingData.eventId;
      await patchData(
        postApi,
        body
      ).then((data) => {openSuccessModal()});
      return;
    }

    //   const provider = await fetchData(`${API_RECRUITMENT}/api/integrations/provider`).then((result) => result.calendar);

    await postData(
      postApi,
      body
    ).then((data) => {openSuccessModal()});
  };

  useEffect(() => {
    setTimeError(false);
  }, [endDate, startDate]);

  return (
    <>
      <div className="popup">
        <form
          className="popup__step"
          onSubmit={(e) => onSubmit(e)}
          ref={popupRef}
        >
          <div className="popup__head">
            <p className="popup__head__headline">Schedule a meeting</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                setIsScheduleMeeting(false);
              }}
              className="popup__head__close"
            >
              <path
                d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
                stroke="#C1C5CB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="popup__body">
            <div className="recruitment__popup-item">
              <label
                className="recruitment__popup-item__label"
                htmlFor="titlemeet"
              >
                Title of meeting <span> *</span>
              </label>
              <input
                className="recruitment__popup-item__input"
                id="titlemeet"
                value={meetingTitle}
                required
                onInvalid={(e) => {
                  e.preventDefault();
                  setMeetingTitleError(true);
                }}
                placeholder="Enter title"
                onChange={(e) => {
                  setMeetingTitle(e.target.value);
                  setMeetingTitleError(false);
                }}
              />
              {meetingTitleError && (
                <p className="recruitment__popup-item__error-message">
                  This is required field
                </p>
              )}
            </div>
            <div className={`recruitment__popup-item`}>
              <p className="recruitment__popup-item__label">
                Date & Time<span> *</span>
              </p>
              <div className="choose-date">
                <div className="choose-date__inner">
                  <p className="choose-date__text">
                    Start:
                    <input
                      value={
                        startDate
                          ? startDate?.toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "numeric",
                              minute: "numeric",
                              year: "numeric",
                              hour: "numeric",
                            })
                          : ""
                      }
                      required
                      placeholder={"Please select date"}
                      onInvalid={(e) => {
                        e.preventDefault();
                        setTimeError(() => true);
                      }}
                      type={"text"}
                    />
                  </p>
                  <p className="choose-date__text">
                    End:
                    <input
                      value={
                        endDate
                          ? endDate?.toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "numeric",
                              minute: "numeric",
                              year: "numeric",
                              hour: "numeric",
                            })
                          : ""
                      }
                      required
                      placeholder={"Please select date"}
                      id={"end"}
                      onInvalid={(e) => {
                        e.preventDefault();
                        setTimeError(() => true);
                      }}
                      type={"text"}
                    />
                  </p>
                </div>
                <button
                  className="button--empty choose-date__button"
                  style={{ padding: "10px 20px" }}
                  type={"button"}
                  onClick={() =>
                    store.dispatch(
                      setCalendarPopupCandidateScoreboard({
                        open: true,
                        startDate: startDate,
                        endDate: endDate,
                      })
                    )
                  }
                >
                  Select date & time
                </button>
                {timeError && (
                  <p className="recruitment__popup-item__error-message">
                    This is required field
                  </p>
                )}
              </div>
            </div>
            <div className="recruitment__popup-item">
              <label
                className="recruitment__popup-item__label"
                htmlFor="roleSelect"
              >
                Guests
              </label>
              <AsyncSelect
                cacheOptions
                loadOptions={() => interviewersCallbackFunc()}
                isMulti
                defaultOptions
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(currentValue: any) => {
                  setInterviewers(currentValue);
                }}
                value={interviewers}
                placeholder={"Select interviewers"}
                components={{
                  Option,
                }}
                styles={selectCustomStyle}
                id="openings"
                instanceId="openings"
              />
            </div>

            <div className="recruitment__popup-item">
              <label
                className="recruitment__popup-item__label"
                htmlFor="roleSelect"
              >
                Remind Everyone Before
              </label>
              <ReactSelect
                options={timeOptions}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setRemindMeType(item.value);
                }}
                value={
                  remindMeTime
                    ? { label: remindMeTime, value: remindMeTime }
                    : timeOptions[0]
                }
                placeholder="Select Time"
                styles={selectCustomStyle}
                id="remindMe"
                instanceId="remindMe"
              />
            </div>
          </div>
          <div className="popup__bottom end">
            <button
              type="reset"
              onClick={() => {
                setIsScheduleMeeting(false);
              }}
              className="popup__cancel"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="popup__bottom__publish button--filled"
            >
              Schedule Meeting
            </button>
            {errorNotification.length > 0 && (
              <p className="error-message">{errorNotification}</p>
            )}
          </div>
        </form>
      </div>
      <EditorModals
        modal={modal?.state}
        setModal={closeSuccessModal}
        modalData={modal?.data}
      />
    </>
  );
};

export default memo(ScheduleMeeting);

const interviewersCallbackFunc = async () => {
  const resData = await fetchData(
    `${API_RECRUITMENT}/api/company/get-lead-users`
  );

  return resData.map((item) => {
    return {
      value: {
        id: item.id,
        name: `${item.firstname} ${item.lastname}`,
        email: item.email,
      },
      label: `${item.firstname} ${item.lastname}`,
      id: item.id,
    };
  });
};

const getInterviewerOptions = (interviewersList) => {
  if (!Array.isArray(interviewersList)) return [];
  return interviewersList.map((item) => ({
    id: item.id,
    label: item.name,
    value: item,
  }));
};

const Option = (props: any) => {
  return (
    <div>
      <components.Option
        {...props}
        className="manage-team-popup__form__checkbox"
      >
        <input
          id={props.value}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label htmlFor={props.value}>
          <span />
        </label>
        <p>{props.label}</p>
      </components.Option>
    </div>
  );
};

function formatDateTime(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12;

  const formattedTime = `${hours}:${minutes} ${period}`;

  return { formattedDate, formattedTime };
}
