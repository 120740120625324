import { store, useTypedSelector } from "../../store";
import { memo } from "react";
import bin from "../../image/icon/delete_ic.svg";
import {
  setPopupWithDelete,
  setPopupWithAssessmentType,
  setOpenPreview,
} from "../../store/reducers/manageAssignmentReducer";
import VideoMeetLoader from "../../../assessment/live-coding/components/video-meeting/Loader/VideoMeetLoader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCandidateIds } from "../../store/reducers/assignmentCandidateReducer";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const tableItemsFunc = (state) => state.manage_assignment.tableItems;
const tableTabsFunc = (state) => state.manage_assignment.tabFilter;

const ManageAssignmentTBody = ({
  horizontalScrollState,
}: {
  horizontalScrollState: boolean;
}) => {
  const tableItems = useTypedSelector(tableItemsFunc);
  const tableTabs = useTypedSelector(tableTabsFunc);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <>
      <tbody className="table__tbody">
          {tableItems?.length===0 && <VideoMeetLoader/>}
          {tableItems?.map((item) => {
          return (
            <tr className="table__tr" key={item.id}>
              <td
                className={`table__td sticky ${
                  horizontalScrollState ? "moved" : ""
                } manage-team__column__middle`}>
                <div className="manage-team__name">
                  <p
                    className="manage-team__name__text manage-team__text--green"
                    style={{ cursor: item?.workflowId !== null ?"pointer" : "not-allowed" }}
                    onClick={()=>{
                      if(item?.workflowId !== null){                        
                        dispatch(setCandidateIds(item?.candidateIds))
                        navigate(`/recruitment/manage-assignment/${item?.jobId}`)
                        dispatch(setOpenPreview({open:true,title:item?.title}))
                      }
                    }}
                  >{`${item?.title}`}</p>
                </div>
              </td>
              {tableTabs.map((value) => {
                if (value.id === 2) {
                  return (
                    value.active && (
                      <td
                        className="table__td actions jobs-table__column__default"
                        key={value.displayName}>
                        <div className="jobs-table__locations">
                          <p className="manage-team__text--gray7 manage-team__text jobs-table__text__no-overflow">
                            {`${item?.candidates}`}
                          </p>
                        </div>
                      </td>
                    )
                  );
                }
              })}
              {tableTabs.map((value) => {
                if (value.id === 3) {
                  return (
                    value.active && (
                      <td
                        className="table__td actions jobs-table__column__default"
                        key={value.displayName}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}>
                          {item?.assessmentType &&
                            item.assessmentType.map((type, index) => (
                              <div
                                key={index}
                                className="manage-team__text--gray7 manage-team__text jobs-table__text__no-overflow">
                                {`${index + 1}.${type}`}
                              </div>
                            ))}
                        </div>

                        <AuthGuard module='assessment' permission='view'>
                          <button
                            className="manage-team__text manage-team__text--green manage-team__text__button"
                            style={{cursor:item?.assessmentType?.length>0?"pointer":"not-allowed",wordBreak:'keep-all'}}
                            onClick={() =>
                              {
                                if(item?.assessmentType?.length>0){
                                store.dispatch(
                                  setPopupWithAssessmentType({
                                    title: item.title,
                                    open: true,
                                    id: item.id,
                                    data: item?.assessmentType,
                                    ids: item?.assessmentIds,
                                  })
                                )}
                              }
                            }>
                            {item?.assessmentType?.length>0 ? "See more" : "N/A"}
                          </button>
                        </AuthGuard>
                      </td>
                    )
                  );
                }
              })}
              {tableTabs.map((value) => {
                if (value.id === 4) {
                  return (
                    value.active && (
                      <td
                        className="table__td actions jobs-table__column__default"
                        key={value.displayName}>
                        <AuthGuard module='assessment' permission='delete'>
                          <img
                            src={bin}
                            alt="delete-icon"
                            className="manageAssignment__table__icon"
                            onClick={() => {
                              store.dispatch(
                                setPopupWithDelete({
                                  open: true,
                                  id: item.id,
                                  title: item.title,
                                })
                              );
                            }}
                          />
                        </AuthGuard>
                      </td>
                    )
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </>
  );
};
export default memo(ManageAssignmentTBody);
