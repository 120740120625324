import { store, useTypedSelector } from "../../store";
import { memo, useEffect, useState } from "react";


import edit from "../../image/icon/edit_ic.svg";
import bin from "../../image/icon/delete_ic.svg";
import plus from "../../image/icon/plus_ic.svg";

import { useNavigate } from "react-router-dom";
import { setOpenPreview, setPopupWithDelete, setPopupWithInstruction } from "../../store/reducers/domainAssessmentReducer";
import { getEnv } from "@urecruits/api";
import { useDispatch } from "react-redux";
import fetchData from "../../hook/http";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_RECRUITMENT } = getEnv()
const tableItemsFunc = state => state.domain_assessment.tableItems
const tableTabsFunc = state => state.domain_assessment.tabFilter

const ManageDomainAssessmentTBody = ({ horizontalScrollState }: { horizontalScrollState: boolean }) => {
	const tableItems = useTypedSelector(tableItemsFunc)
	const tableTabs = useTypedSelector(tableTabsFunc)
	const navigate = useNavigate()
	const [industryData, setIndustryData] = useState([]);
	const [departmentData, setDepartmentData] = useState([]);
	const dispatch = useDispatch()

	useEffect(() => {
		fetchData(`${API_RECRUITMENT}/api/industry/label/`)
			.then((data) => {
				if (data) {
					setIndustryData(data);
				}
			});

		fetchData(`${API_RECRUITMENT}/api/department/label/`)
			.then((data) => {
				if (data) {
					setDepartmentData(data);
				}
			});
	}, []);

	const handleIndustry = (item) => {
		const label = industryData.length > 0 && industryData.find((info) => info.id === item)?.label;
		return label;
	};

	const handleDepartment = (item) => {
		const label = departmentData.length > 0 && departmentData.find((info) => info.id === item)?.label;
		return label;
	};

	return (
		<>
			<tbody className="table__tbody">
				{
					tableItems?.map(item => {
						return <tr className="table__tr" key={item.id}>
							<td className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} manage-team__column__middle`}>
								<div className='manage-team__name'>
									<p className='manage-team__name__text manage-team__text--green' style={{ cursor: "pointer" }} onClick={() => { dispatch(setOpenPreview({ isOpen: true, id: item?.id })) }}>{`${item?.assessmentName}`}</p>
								</div>
							</td>
							{
								tableTabs.map(value => {
									if (value.id === 2) {
										return value.active &&
											<td className="table__td actions jobs-table__column__default" key={value.displayName}>
												<div className='jobs-table__locations'>
													<p className='manage-team__text--gray7 manage-team__text jobs-table__text__no-overflow'>
														{
															handleIndustry(item?.industryId)
														}
													</p>
												</div>
											</td>
									}
								})
							}
							{
								tableTabs.map(value => {
									if (value.id === 3) {
										return value.active &&
											<td className="table__td actions jobs-table__column__default" key={value.displayName}>
												<div className='jobs-table__locations'>

													{
														<p className='manage-team__text--gray7 manage-team__text '>{handleDepartment(item.department)}</p>
													}
												</div>
											</td>
									}
								})
							}
							{
								tableTabs.map(value => {
									if (value.id === 4) {
										return value.active &&
											<td className="table__td actions jobs-table__column__default" key={value.displayName}>
												<div className='jobs-table__locations'>
													{
														<p className='manage-team__text--gray7 manage-team__text '>{item?.timeDuration}</p>
													}
												</div>
											</td>
									}
								})
							}
							{
								tableTabs.map(value => {
									if (value.id === 5) {
										return value.active &&
											<td className="table__td actions jobs-table__column__default" key={value.displayName}>
												<div className='jobs-table__locations'>
													<p className='manage-team__text--gray7 manage-team__text '>{item.score}</p>
												</div>
											</td>
									}
								})
							}
							{
								tableTabs.map(value => {
									if (value.id === 6) {
										return value.active &&
											<td className="table__td actions jobs-table__column__default" key={value.displayName}>
												<div className='jobs-table__locations'>
													{
														item.instruction ?
															<button className='manage-team__text manage-team__text--green manage-team__text__button'
																onClick={() => {
																	store.dispatch(setPopupWithInstruction({
																		open: true,
																		id: item.id,
																		instruction: item.instruction || null,
																		title: item.assessmentName
																	}))

																}}>
																See Instruction
															</button>
															: <>
																<AuthGuard module='assessment' permission='edit'>
																	<button className="manage-team__text--gray7 manage-team__text"
																		onClick={() => {
																			store.dispatch(setPopupWithInstruction({
																				open: true,
																				id: item.id,
																				instruction: "",
																				title: item.assessmentName
																			}))
																		}}
																	>
																		<img src={plus} alt="Edit-icon" className="manageAssignment__table__icon"

																		/>Instruction</button>
																</AuthGuard>
															</>
													}
												</div>
											</td>
									}
								})
							}
							{
								tableTabs.map(value => {
									if (value.id === 7) {
										return value.active &&
											<td className="table__td actions jobs-table__column__default" key={value.displayName}>
												<AuthGuard module='assessment' permission='edit'>
													<img src={edit} alt="Edit-icon" className="manageAssignment__table__icon"
														onClick={(e) => {
															navigate(`/manage-domain/edit/${item.id}`)
														}}
													/>
												</AuthGuard>
												<AuthGuard module='assessment' permission='delete'>
													<img src={bin} alt="delete-icon" className="manageAssignment__table__icon"
														onClick={() => {
															store.dispatch(setPopupWithDelete({
																open: true,
																id: item.id,
																title: item.assessmentName
															}))
														}}
													/>

												</AuthGuard>
											</td>
									}
								})
							}
						</tr>
					})
				}
			</tbody>
		</>)
}
export default memo(ManageDomainAssessmentTBody)

