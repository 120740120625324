import { Outlet, useNavigate } from "react-router-dom";
import {
	PaginationComponent,
	SearchFieldComponent,
	TheadItemComponent
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import TableEmpty from "../components/Global/table/TableEmpty";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import { store, useTypedSelector } from "../store";
import { memo, useCallback, useLayoutEffect, useState } from "react";
import fetchData from "../hook/http";
import { sortingFunc } from "../utils/sortingFuncForTables";
import { IMTMUser } from "../types/redux/manage-team-members";
import {
	defaultWorkflowFilters, setCurrentPageWorkflow, setItemsWorkflow, setLimitWorkflow, setNoItemsSearchWorkflow,
	setSearchFilterWorkflow,
	setSortByFilterWorkflow,
	setSortTypeFilterWorkflow, setTableEmptyWorkflow, setTotalCountWorkflow
} from "../store/reducers/positionWorkFlowReducer";
import { getEnv } from "@urecruits/api";
import { transformDate } from "../utils/transformDate";
import TBodyWorkflow from "../components/PositionWorkflow/table/TBodyWorkflow";
import PopupWithList from "../components/PositionWorkflow/table/PopupWithList";
import PopupAssignWorkflow from "../components/PositionWorkflow/table/PopupAssignWorkflow";
import DeletePopupWorkFlow from "../components/PositionWorkflow/table/DeletePopup";
import MobileTableWorkflows from "../components/PositionWorkflow/table/MobileTableWorkflows";
import { useHasPermission, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const {API_RECRUITMENT} = getEnv()

const getTableWorkflow = state => state.position_workflow

const PositionWorkflowScreen = () => {

	const {checkUserPermission} = useHasPermission()
	const navigate = useNavigate()
	const table = useTypedSelector(getTableWorkflow)
	const [horizontalScrollState, setHorizontalScrollState] = useState(false);

	useLayoutEffect(() => {
		setItemsPositionWorkflow(table.pagination.currentPage, table.pagination.limit, table.filters)
	}, []);

	const filterArray = [table.filters.searchValue, table.filters.sortBy, table.filters.sortType, table.pagination]

	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeFilterWorkflow, setSortByFilterWorkflow, setItemsPositionWorkflow), filterArray);
	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchFilterWorkflow(value))
		checkUserPermission('job-post','view') && setItemsPositionWorkflow(1, table.pagination.limit, {...table.filters, searchValue: value})
	}, filterArray);

	const emptyTableCallback = useCallback(() => navigate('/recruitment/position-workflow/create'), [])

	return <section>
		<Outlet/>
		{
			table.popupWithList.open&&<PopupWithList/>
		}
		{
			table.assignPopup&&<PopupAssignWorkflow/>
		}
		{
			table.deletePopup.open&&<DeletePopupWorkFlow/>
		}
		<div className='table-screen-top'>
			<h2>Position Workflows</h2>
			<AuthGuard module='job-post' permission='add'>
			<button className='button--filled table-screen-top__button' onClick={() => navigate('/recruitment/position-workflow/create')}>Add New</button>
			</AuthGuard>
		</div>
		<div className="table__wrapper">
			<div className="table__top">
				<AuthGuard module='job-post' permission='view'>
				<div className='manage-team__top__left'>
					<SearchFieldComponent searchValue={table.filters.searchValue} setSearchValue={setSearchCallback}
					                      placeholder={'Search workflow'}/>
				</div>
				</AuthGuard>
			</div>
			{
				table.emptySearch || table.emptyTable ? (
						table.emptyTable ?
							<TableEmpty 
								handler={checkUserPermission('job-post','add') ? emptyTableCallback : ()=>navigate("/")} 
								cta={checkUserPermission('job-post','add') ? 'Create New Workflow': 'Go to Dashboard'} 
								title={'Table is empty'}
								desc={checkUserPermission('job-post','add') ? 'Workflow table is empty, create your first workflow':''}
								/> :
							<NoResultsSearch limit={table.pagination.limit} resetFunc={resetFilters}/>)
					:
					<AuthGuard module={'job-post'} permission='view'>
						<>
						<table className='table' onScroll={(e: any) => {
							if (e.target.scrollLeft > 10 && !horizontalScrollState) setHorizontalScrollState(() => true)
							else if (e.target.scrollLeft < 10 && horizontalScrollState) setHorizontalScrollState(() => false)
						}}>
							<thead className="table__thead">
							<td
								className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} table__td--thead manage-team__column__middle`}>
								<TheadItemComponent title={table.fixedTab.displayName} dbName={'title'}  handler={tdOrderCallback}/>
							</td>
							{
								table.tabFilter.map((item) => {
									if(item.displayName === 'Actions' && !checkUserPermission('job-post',['add','edit','delete'])){
										return
									}
									return item.active &&
                      <td className={`table__td  table__td--thead manage-team__column__middle`} key={item.id}>
                          <TheadItemComponent title={item.displayName}
                                              handler={item.dbName ? tdOrderCallback : null} dbName={item.dbName}/>
                      </td>
								})
							}
							</thead>
										<TBodyWorkflow horizontalScrollState={horizontalScrollState} />
						</table>
						<div className='table-mobile'>
						{
							table.tableItems.map(item=>{
								return <MobileTableWorkflows key={item.id} item={item}/>
							})
						}
					</div>
						<PaginationComponent
							limit={table.pagination.limit}
							currentPage={table.pagination.currentPage}
							totalCount={table.pagination.totalCount}
							setCurrentPage={setItemsPositionWorkflow}
							setLimit={setLimitHandler}
							filters={table.filters}
						/>
					</>
					</AuthGuard>
			}
		</div>
	</section>
}
export default memo(PositionWorkflowScreen)

export const setItemsPositionWorkflow = (page: number, limit: number, filters: any) => {
		store.dispatch(setCurrentPageWorkflow(page))
	const getData = async () => {
		fetchData(`${API_RECRUITMENT}/api/workflow/company?limit=${limit}&offset=${(page - 1) * limit}
		${filters.searchValue ? '&search=' + encodeURIComponent(filters.searchValue) : ''}
		${filters.sortBy ? '&sortBy=' + filters.sortBy : ''}
		${filters.sortType ? '&sortType=' + filters.sortType : ''}
		`)
			.then((res) => {
				store.dispatch(setTotalCountWorkflow(res.count))
				const items = transformArrayToTableData(res.rows)
				store.dispatch(setItemsWorkflow(items))
				if (items.length === 0) {
					if (filters.searchValue === '') {
						store.dispatch(setTableEmptyWorkflow(true))
					} else {
						store.dispatch(setNoItemsSearchWorkflow(true))
					}
				} else {
					store.dispatch(setTableEmptyWorkflow(false))
					store.dispatch(setNoItemsSearchWorkflow(false))
				}
			})
	}
	getData().then()
}
const setLimitHandler = (value: any) => {
	store.dispatch(setLimitWorkflow(value))
}

const transformArrayToTableData = (array: any): Array<IMTMUser> => {
	return array.map(item => {
		return {
			id:item.id,
			workflow:item.workflow,
			createdBy:`${item.author.firstname} ${item.author.lastname}`,
			createdAt:transformDate(item.createdAt),
			title:item.title
		}
	})
}


const resetFilters = (pagination) => {
	store.dispatch(setSortByFilterWorkflow('id'))
	store.dispatch(setSortTypeFilterWorkflow('DESC'))
	store.dispatch(setSearchFilterWorkflow(''))
	setItemsPositionWorkflow(1, pagination, defaultWorkflowFilters)
}