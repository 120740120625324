import { useTypedSelector} from "../../store";
import {memo} from "react";
//TODO do not delete

import defaultAvatar from "../../image/icon/avatar.svg";
import eye from "../../image/icon/eye-on.svg";
import {useNavigate} from "react-router-dom";



const tableItemsFunc=state=>state.manage_candidate.tableItems
const tableTabsFunc=state=>state.manage_candidate.tabFilter
const ManageCandidatesTBody = ({horizontalScrollState}: { horizontalScrollState: boolean }) => {

	const tableItems=useTypedSelector(tableItemsFunc)
	const tableTabs=useTypedSelector(tableTabsFunc)
const navigate=useNavigate()
	return <>
		<tbody className="table__tbody">
		{
			tableItems.map(item => {
				return <tr className="table__tr" key={item.id}>
					<td className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} manage-team__column__middle`}>
						<div className='manage-team__name'>
							<img src={item.avatar || defaultAvatar} alt='avatar' className='manage-team__name__avatar'/>
							<p className='manage-team__name__text'>{`${item.firstname} ${item.middlename || ''} ${item.lastname}`}</p>
						</div>
					</td>
					{
						tableTabs.map(value => {
							if (value.id === 3) {
								return value.active &&
                    <td className="table__td actions jobs-table__column__default" key={value.displayName}>
                        <div className='jobs-table__locations'>
                            <p className='manage-team__text--gray7 manage-team__text jobs-table__text__no-overflow'>{item.location}</p>
                        </div>
                    </td>
							}
						})
					}
					{
						tableTabs.map(value => {
							if (value.id === 4) {
								return value.active &&
                    <td className="table__td actions jobs-table__column__default" key={value.displayName}>
                        <div className='jobs-table__locations'>

	                        {
		                        (item.experience||item.experience===0)&&<p className='manage-team__text--gray7 manage-team__text '>{item.experience} years</p>
	                        }
                        </div>
                    </td>
							}
						})
					}
					{
						tableTabs.map(value => {
							if (value.id === 5) {
								return value.active &&
                    <td className="table__td actions jobs-table__column__default" key={value.displayName}>
                        <div className='jobs-table__locations'>
	                        {
		                        item.salary&&item.salary!==0?<p className='manage-team__text--gray7 manage-team__text '>${item.salary}</p>:null
	                        }
                        </div>
                    </td>
							}
						})
					}
					{
						tableTabs.map(value => {
							if (value.id === 6) {
								return value.active &&
                    <td className="table__td actions jobs-table__column__default" key={value.displayName}>
                        <div className='jobs-table__locations'>
                            <p className='manage-team__text--green manage-team__text '>{item.phone}</p>
                        </div>
                    </td>
							}
						})
					}
					{
						tableTabs.map(value => {
							if (value.id === 7) {
								return value.active &&
                    <td className="table__td actions jobs-table__column__default" key={value.displayName}>
                        <div className='jobs-table__locations'>
                            <p className='manage-team__text--green manage-team__text '>{item.email}</p>
                        </div>
                    </td>
							}
						})
					}
					{
						tableTabs.map(value => {
							if (value.id === 11) {
								return value.active &&
                    <td className="table__td actions jobs-table__column__default" key={value.displayName}>
                        <img src={eye} alt="delete-icon" className="manageAssignment__table__icon"
                             onClick={(e) => {
	                             navigate(`/recruitment/candidate-profile/${item.id}`)
		                         }}
                        />
                    </td>
							}
						})
					}
				</tr>
			})
		}
		</tbody>
	</>
}
export default memo(ManageCandidatesTBody)

