import "../styles/main.scss";
import { store } from "../store";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import PositionWorkflowScreen from "../screen/PositionWorkflowScreen";
import {
  AddNewWorkflow,
  EditWorkflow,
} from "../components/HOCs/PositionWorkflowPopupHOC";
import ManageTeamMembers from "../screen/ManageTeamMembers";
import ManageTeamPopup from "../components/ManageTeamMembers/Popup/ManageTeamPopup";
import { EditMember } from "../components/HOCs/MTMPopupHoc";
import JobsScreen from "../screen/JobsScreen";
import DetailViewJobTable from "../screen/DetailViewJobTableMatched";
import ManageCandidatesScreen from "../screen/ManageCandidatesScreen";
import ManageCandidateProfileScreen from "../screen/ManageCandidateProfileScreen";
import DomainAssessmentScreen from "../screen/DomainAssessmentScreen";
import ScreeningHiringDashboard from "../screen/ScreeningHiringDashboard";
// import ScreeningHiringCandidateDashboard from "../screen/ScreeningHiringCandidateDashboard";
import CandidateScoreboardScreen from "../screen/CandidateScoreboardScreen";
import CalendarScreen from "../screen/CalendarScreen";
import UserInformationScreen from "../screen/UserInformationScreen";
// import DomainAssessmentReviewAndScoreScreen from "../screen/DomainAssessmentReviewAndScoreScreen";
import CandidateDomainScreen from "../screen/CandidateDomainScreen";
import JobsOffersScreen from "../screen/JobsOffersScreen";
import UsersOffersScreen from "../screen/UsersOffersScreen";
import GenerateOfferLetterScreen from "../screen/GenerateOfferLetterScreen";
import OfferPreviewScreen from "../screen/OfferPreviewScreen";
import HellosignScreen from "../screen/HellosignScreen";
// import ReviewAndScoreScreen from "../screen/ReviewAndScoreScreen"
import AnswerSheetScreen from "../screen/AnswerSheetScreen";
import ReviewScreen from "../screen/Review";
import ScoreboardScreen from "../screen/ScoreboardScreen";
import MailBoxScreen from "../screen/MailBoxScreen";
import ManageAssignmentScreen from "../screen/ManageAssignmentScreen";
import AssignmentCandidateScreen from "../screen/AssignmentCandidateScreen";
import OfferViewScreen from "../screen/OfferViewScreen";
import AssessmentsDashboard from '../screen/AssessmentsDashboard';
import RecruitmentDashboard from '../screen/RecruitmentDashboard';
import { BackgroundScreeningJobs, BackgroundScreeningCandidates, InterviewsJobs, InterviewsCandidates, DrugScreeningJobs, DrugScreeningCandidates } from "../screen";
import OffersSign from '../../globalstyle/screen/OffersSign';
// import { ThemeProvider } from "@mui/material/styles";
// import theme from "../utils/materialTheme";
import { permissionRoutesType, useHasPermission, UnAuthorizePageComponent, NotFoundPageComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";


const routes:Array<permissionRoutesType>=[
  //Calendar
  {
    path:"/recruitment/calendar",
    element: <CalendarScreen />,
    module:['recruiter','candidate'],
    permissions:''
  },
  //Mails
  {
    path:"/recruitment/mail-box",
    element: <MailBoxScreen />,
    module:['recruiter','candidate'],
    permissions:''
  },
  //Recruitment
  {
    path:"recruitment/home",
    element: <RecruitmentDashboard />,
    module:'recruiter',
    permissions:''
  },
  //position-workflow
  {
    path:"recruitment/position-workflow",
    element: <PositionWorkflowScreen />,
    module:'job-post',
    permissions:['view','add','edit']
  },
  {
    path:"recruitment/position-workflow/create",
    element:  <AddNewWorkflow titleTop={"Add New Workflow"} navigateLink={"/recruitment/position-workflow"} />,
    module:'job-post',
    permissions:'add'
  },
  {
    path:"recruitment/position-workflow/:id/edit",
    element:  <EditWorkflow titleTop={"Edit Workflow"} navigateLink={"/recruitment/position-workflow"} />,
    module:'job-post',
    permissions:'edit'
  },
  //team-members
  {
    path:"recruitment/manage-team-members",
    element: <ManageTeamMembers />,
    module:'team-members',
    permissions:['view','add','edit']
  },
  {
    path:"recruitment/manage-team-members/add-new",
    element: <ManageTeamPopup title={"Add New Member"} />,
    module:'team-members',
    permissions:'add'
  },
  {
    path:"recruitment/manage-team-members/edit/:id",
    element: <EditMember title={"Edit Member"} />,
    module:'team-members',
    permissions:'edit'
  },
  //Jobs
  {
    path:"recruitment/jobs",
    element: <JobsScreen />,
    module:'job-post',
    permissions:['view','add','edit']
  },
  {
    path:"recruitment/job/:id/matched-profiles",
    element: <DetailViewJobTable />,
    module:'job-post',
    permissions:'view'
  },
  {
    path:"recruitment/job/:jobId/matched-profiles/:userId",
    element: <UserInformationScreen />,
    module:'job-post',
    permissions:'view'
  },
  {
    path:"recruitment/job/:jobid/candidate-scoreboard/:id",
    element: <CandidateScoreboardScreen />,
    module:'job-post',
    permissions:['view','edit']
  },
  //Candidate-profile
  {
    path:"recruitment/candidate-profile",
    element: <ManageCandidatesScreen />,
    module:'recruiter',
    permissions:''
  },
  {
    path:"recruitment/candidate-profile/:id",
    element: <ManageCandidateProfileScreen />,
    module:'recruiter',
    permissions:''
  },

  //Assessment
  {
    path:"recruitment/assessments",
    element: <AssessmentsDashboard />,
    module:'assessment',
    permissions:['view','edit','add']
  },
  //Manage-Assignment
  {
    path:"recruitment/manage-assignment",
    element: <ManageAssignmentScreen />,
    module:'assessment',
    permissions:['view','add']
  },
  {
    path:"recruitment/manage-assignment/:jobId",
    element: <AssignmentCandidateScreen />,
    module:'assessment',
    permissions:'view'
  },
  //Domain-Assessment
  {
    path:"assessment/domain-assessment",
    element: <DomainAssessmentScreen />,
    module:'assessment',
    permissions:['view','edit','add']
  },
  //Review & Score
  {
    path:"/recruitment/review-score",
    // element: <ReviewScreen edit={roleEditJobPermission} add={roleAddJobPermission} />,
    element: <ReviewScreen edit={true} add={true} />,
    module:'assessment',
    permissions:['view','edit']
  },
  {
    path:"/recruitment/score-board/:assessmentId/:jobId",
    element: <CandidateDomainScreen />,
    module:'assessment',
    permissions:'view'
  },
  {
    path:"/recruitment/answer-sheet/:resultId/:candidateName",
    element: <AnswerSheetScreen />,
    module:['assessment','job-post'],
    permissions:['edit','view'],
  },
  //Scoreboard
  {
    path:"/recruitment/scoreboard",
    element: <ScoreboardScreen />,
    module:'job-post',
    permissions:'view',
  },

  //Screening & Hiring
  {
    path:"recruitment/screening-hiring",
    element: <ScreeningHiringDashboard />,
    module:'job-post',
    permissions:'view'
  },

  //Interviews
  {
    path:"recruitment/interviews/jobs",
    element: <InterviewsJobs />,
    module:'job-post',
    permissions:'view'
  },
  {
    path:"recruitment/interviews/candidates/:jobId",
    element: <InterviewsCandidates />,
    module:'job-post',
    permissions:['view','edit']
  },
  //Offer
  {
    path:"/recruitment/offers",
    element: <JobsOffersScreen />,
    module:['offer','job-post'],
    permissions:['view'],
    option:"AND"
  },
  {
    path:"/recruitment/offers/sign/:offerId",
    element: <OffersSign callbackUrl="/recruitment/offers" />,
    module:'recruiter',
    permissions:''
  },
  {
    path:"recruitment/offers/:id",
    element: <UsersOffersScreen />,
    module:['offer','job-post'],
    permissions:'view',
    option:"AND"
  },
  {
    path:"recruitment/offers/:jobId/:userId/:roundId/:subscribeId",
    element: <GenerateOfferLetterScreen />,
    module:'offer',
    permissions:'add'
  },
  {
    path:"recruitment/offers/edit/:offerId",
    element: <GenerateOfferLetterScreen edit={true} />,
    module:'offer',
    permissions:'edit'
  },
  {
    path:"recruitment/offers/view/:offerId",
    element: <OfferViewScreen />,
    module:'offer',
    permissions:'view'
  },
  {
    path:"recruitment/offers/preview/:jobId/:userId",
    element: <OfferPreviewScreen />,
    module:['offer','job-post'],
    permissions:'view',
    option:"AND"
  },
  {
    path:"recruitment/test-hellosign",
    element: <HellosignScreen />,
    module:[],
    permissions:''
  },
  //Background Screening
  {
    path:"recruitment/background-screening/jobs",
    element: <BackgroundScreeningJobs />,
    module:['job-post','background'],
    permissions:'view',
    option:"AND"
  },
  {
    path:"recruitment/background-screening/candidates/:jobId",
    element: <BackgroundScreeningCandidates />,
    module:['job-post','background'],
    permissions:'view',
    option:"AND"
  },

  //Drug Screening
  {
    path:"recruitment/drug-screening/jobs",
    element: <DrugScreeningJobs />,
    module:['job-post','drug'],
    permissions:'view',
    option:"AND"
  },
  {
    path:"recruitment/drug-screening/candidates/:jobId",
    element: <DrugScreeningCandidates />,
    module:['job-post','drug'],
    permissions:'view',
    option:"AND"
  },
]

export default function Root() {

  const {onAuthorizedRoutes} = useHasPermission()

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
        {routes.map(route => {
            return <Route path={route.path} key={route.path} element={onAuthorizedRoutes(route.module, route.permissions,route?.option||"") ? route.element : <UnAuthorizePageComponent />} />
          })}
          <Route path="/recruitment/*" element={<NotFoundPageComponent/>}/>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}
