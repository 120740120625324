import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Skeleton, Stack } from "@mui/material";
import { getEnv } from "@urecruits/api";
import SingleMobileItem from "@ucrecruits/globalstyle/components/Table/MobileTable/SingleMobileItem";
import fetchData from "../../hook/http";
import { DashboardEmptyDataComponent, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import WorkflowPopup from './Popup/wokflowPopup';
const {API_ASSESSMENT,API_RECRUITMENT} = getEnv();

const options: any = [
	{value: "1", label: "1"},
	{value: "2", label: "2"},
	{value: "3", label: "3"},
	{value: "4", label: "4"},
	{value: "5", label: "5"},
	{value: "6", label: "6"},
];
const colNames = ["Job Title","Candidate No.","Type"]
const ManageAssignmentTable = () => {
    const [data,setData] = useState([])
    const [isLoading,setIsLoading] = useState(true)
    const [isEmptyData,setIsEmptyData] = useState(false)
    const [workflowData,setWorkflowData] = useState({visible:false,title:"",workflows:[]})
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const { checkUserPermission } = useHasPermission();

    useEffect(() => {
        const updateScreenSize = () => {
        setScreenWidth(window.innerWidth);
        };
        addEventListener("resize", updateScreenSize);
        return () => {
        removeEventListener("resize", updateScreenSize);
        };
    }, []);
    const assessmentPermission = checkUserPermission('assessment', 'view');

    useEffect(()=>{
      async function getInfo() {
        try{
        fetchData(`${API_ASSESSMENT}/api/assignment/all?limit=20&offset=0`).then(data=>{
          if(!data?.rows.length){
            setIsEmptyData(true)
          }else{
            setData(data?.rows || [])
          }
          setIsLoading(false)
        })
        }catch(error){
          console.log(error)
          setIsEmptyData(true)
          setIsLoading(false)
        }
      }
      assessmentPermission && getInfo()
    },[assessmentPermission])
    const showWorkflow = (assignment)=>{
      const wfls = [];
      if(assignment.domainAssessment){
        wfls.push({name:"Functional/Domain Assessment"})
      }
      if(assignment.liveCoding){
        wfls.push({name:"Live Task/Coding Assessment"})
      }
      if(assignment.takeHomeTask){
        wfls.push({name:"Take Home/Coding Assessment"})
      }
      if(wfls.length>0){
        setWorkflowData({visible:true,workflows:wfls,title:assignment.title})
      }
    }
	return (
		<div className="dashboard-table">
      {
        workflowData.visible && <WorkflowPopup workflow={{title:workflowData.title,workflows:workflowData.workflows}} onClose={()=>setWorkflowData(prev=>({...prev,visible:false}))} />
      }
			<div className="dashboard-table__header">
				<p className="dashboard-table__header__title">
					Manage Assignment
				</p>
				<Link to={"/recruitment/manage-assignment"} className="dashboard-table__header__link">View all</Link>
			</div>
            {
              isLoading && <Stack height={"100%"} padding={"12px"}>
                  <Skeleton animation="wave" />
              </Stack>
            }
            {
              isEmptyData ? <DashboardEmptyDataComponent/>
              :
              <>
                {
                  screenWidth <= 768 && data.length >0 && <>
                     <div className="dashboard-table__table">
                      <div className="dashboard-table__tbody">
                        {data.map(item=><MobileItem item={item} key={item.id} showWorkflow={showWorkflow}/>)}
                      </div>
                    </div>
                  </>
                }
                {
                  screenWidth > 768 && data.length >0 && <>
                  <table className="dashboard-table__table">
                      <thead className="dashboard-table__thead">
                          {
                            colNames.map((col,index)=>{
                                return <td className="dashboard-table__thead__td" key={index}>
                                {col}
                            </td>
                            })
                          }
                      </thead>
                      <tbody className="dashboard-table__tbody">
                          {
                            data.length>0 && <>
                            {
                                data.map(assignment=>{
                                  let assessments = 0;
                                  if(assignment.domainAssessment){
                                    assessments++;
                                  }
                                  if(assignment.liveCoding){
                                    assessments++;
                                  }
                                  if(assignment.takeHomeTask){
                                    assessments++;
                                  }
                                    return <tr className="dashboard-table__tbody__tr" key={assignment.id}>
                                    <td className="dashboard-table__tbody__td">
                                        <Link to={`/recruitment/manage-assignment/${assignment.jobId}`} className="dashboard-table__link">
                                            {assignment.title || ""}
                                        </Link>
                                    </td>
                                    <td className="dashboard-table__tbody__td">
                                    {assignment.candidate?.length || 0}
                                    </td>
                                    <td className="dashboard-table__tbody__td">
                                        <div className="dashboard-table__tbody__td__type">
                                        <p>Contains {assessments} assesments</p>
                                        {assessments>0 && 
                                        <p className="dashboard-table__text green" style={{cursor:'pointer'}} onClick={()=>showWorkflow(assignment)}> see more</p>
                                        }
                                        </div>
                                    </td>
                                </tr>
                                })
                            }
                            </>
                          }
                      </tbody>
                  </table>
                  </>
                }
            </>
            }
		</div>
	);
};

export default ManageAssignmentTable;


const MobileItem =({item,showWorkflow})=>{
    const [openItem,setOpenItem] = useState(false)
    let assessments = 0;
    if(item.domainAssessment){
      assessments++;
    }
    if(item.liveCoding){
      assessments++;
    }
    if(item.takeHomeTask){
      assessments++;
    }

    return <div className="table-mobile__item dashboard-table__mobile__item">
    <div
      className={`table-mobile__top ${openItem ? "active" : ""}`}
      onClick={() => {
        setOpenItem((prev) => !prev);
      }}>
      <div className="table-mobile__top__left">
        <p className="table-mobile__top__name dashboard-table__mobile__item__text__bold">
        <Link to={`/recruitment/manage-assignment/${item.jobId}`} className="dashboard-table__link">
            {item.title || ""}
        </Link>
        </p>
      </div>
      <ArrowSvg />
    </div>
    <div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
        <SingleMobileItem title={colNames[0]} content={item.title || ""} textStyle="table-mobile__item__text"/>
        <SingleMobileItem title={colNames[1]} content={item.candidate?.length || 0} textStyle="table-mobile__item__text"/>
        <SingleMobileItem 
            title={colNames[2]} 
            content={
              <div className="dashboard-table__tbody__td__type">
                <p>Contains {assessments} assesments</p>
                {assessments>0 && 
                <p className="dashboard-table__text green" style={{cursor:'pointer'}} onClick={()=>showWorkflow(item)}> see more</p>
                }
              </div>
            } 
            textStyle="table-mobile__item__text"
        />
    </div>
  </div>
}

const ArrowSvg = () => {
    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6751_20970)">
          <path
            d="M1 3L5 7L9 3"
            stroke="#5F6E80"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_6751_20970">
            <rect
              width="10"
              height="10"
              fill="white"
              transform="translate(0 10) rotate(-90)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  };