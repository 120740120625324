import { memo, useState } from "react";
import { IMTMUser } from "../../../../types/redux/manage-team-members";
import defaultAvatar from "../../../../image/icon/avatar.svg";
import { ManageTeamMembersTableTabsEnums } from "../../../../enums/manage-team-members/ManageTeamMembersEnums";
import { transformDate } from "../../../../utils/transformDate";
import resend from "../../../../image/icon/resent invite_ic.svg";
import editIcon from "../../../../image/icon/edit_ic.svg";
import deleteIcon from "../../../../image/icon/delete_ic.svg";
import { store } from "../../../../store";
import { setDeletePopupMTM, setResendPopupMTM } from "../../../../store/reducers/manageTeamMembersReducer";
import { useNavigate } from "react-router-dom";
import SingleItem from "../../../Global/table/SingleMobileItem";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const MobileTableItem = ({item}: { item: IMTMUser}) => {

	const [openItem, setOpenItem] = useState(false);
	const navigate = useNavigate()

	return <div className='table-mobile__item'>
		<div className={`table-mobile__top ${openItem ? 'active' : ''}`} onClick={() => {
			setOpenItem(prev => !prev)
		}}>
			<div className='table-mobile__top__left'>
				<img src={item.avatar || defaultAvatar} alt='avatar' className='table-mobile__avatar'/>
				<p className='table-mobile__top__name'>{`${item.firstname} ${item.middlename?item.middlename:''} ${item.lastname}`}</p>
			</div>
			<ArrowSvg/>
		</div>
		<div className={`table-mobile__item__body ${openItem ? 'open' : ''}`}>
			<SingleItem title={ManageTeamMembersTableTabsEnums.ID+':'} text={item.id} textStyle={'table-mobile__item__text'}/>
			<SingleItem title={ManageTeamMembersTableTabsEnums.EMAIL+':'} text={item.email} textStyle={'table-mobile__item__text table-mobile__item__text--green'}/>
			<SingleItem title={ManageTeamMembersTableTabsEnums.PHONE+':'} text={item.phone} textStyle={'table-mobile__item__text table-mobile__item__text--green'}/>
			<SingleItem title={ManageTeamMembersTableTabsEnums.JOB_TITLE+':'} text={item.jobTitle} textStyle={'table-mobile__item__text'}/>
			<SingleItem title={ManageTeamMembersTableTabsEnums.DEPARTMENT+':'} text={item.department} textStyle={'table-mobile__item__text'}/>
			<SingleItem title={ManageTeamMembersTableTabsEnums.JOB_LOCATION+':'} text={item.jobLocation} textStyle={'table-mobile__item__text'}/>
			<SingleItem title={ManageTeamMembersTableTabsEnums.DATE_OF_JOINING+':'} text={transformDate(item.dateOfJoining)} textStyle={'table-mobile__item__text'}/>
			<SingleItem title={ManageTeamMembersTableTabsEnums.DATE_OF_BIRTH+':'} text={transformDate(item.dateOfBirth)} textStyle={'table-mobile__item__text'}/>
			<SingleItem title={ManageTeamMembersTableTabsEnums.ROLE+':'} text={item.role.join(', ')} textStyle={'table-mobile__item__text'}/>
			<SingleItem title={ManageTeamMembersTableTabsEnums.ADDRESS+':'} text={item.address} textStyle={'table-mobile__item__text'}/>
			<div className='table-mobile__item__wrap'>
				<div className='table-mobile__item__status'>
					<p className={`${item.status==='Pending'?'table__status-block table__status-block--yellow manage-team__status':'table__status-block table__status-block--green manage-team__status'}`}>{item.status}</p>
				</div>
			</div>
			<div className="table-mobile__item__body__action">
			{
				item.status==='Pending'&&
				<AuthGuard module='team-members' permission='edit'>

				<button className='table-mobile__item__action-button table-mobile__item__action-button--green' onClick={()=>{
					store.dispatch(setResendPopupMTM({
						open:true,
						id:parseInt(item.id)
					}))
				}}>
					<img src={resend} alt="delete-icon" />
					Resend invite
				</button>
				</AuthGuard>
			}
			{
				!item.role.includes('Company Owner') && 
				<>
					<AuthGuard module='team-members' permission='edit'>
						<button
								className={`${!item.role.includes('Company Owner') ? 'table-mobile__item__action-button table-mobile__item__action-button--half' : 'table-mobile__item__action-button'}`}
								onClick={() => {
									navigate(`/recruitment/manage-team-members/edit/${item.id}`)
								}}
								>
								<img src={editIcon} alt="delete-icon"/>
								Edit fields
						</button>
					</AuthGuard>
					<AuthGuard module='team-members' permission='delete' >
						<button className='table-mobile__item__action-button table-mobile__item__action-button--half'
									onClick={() => store.dispatch(setDeletePopupMTM({
										open: true,
										name: `${item.firstname} ${item.middlename?item.middlename:''} ${item.lastname}`,
										id: parseInt(item.userId)
									}))
									}
					>
						<img src={deleteIcon} alt="delete-icon"/>
						Delete
					</button>
					</AuthGuard>
				</>
			}
			</div>
		</div>
	</div>
}
export default memo(MobileTableItem)

const ArrowSvg = () => {
	return <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_6751_20970)">
			<path d="M1 3L5 7L9 3" stroke="#5F6E80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_6751_20970">
				<rect width="10" height="10" fill="white" transform="translate(0 10) rotate(-90)"/>
			</clipPath>
		</defs>
	</svg>
}