import React, {memo, useLayoutEffect, useState} from "react";
import DetailsBlock from "../components/CandidateProfile/DetailsBlock";
import defaultAvatar from '../image/icon/avatar.svg'
import DetailsRow from "../components/CandidateProfile/DetailsRow";
import phoneIC from '../image/icon/phone_candidate_profile_ic.svg'
import emailIC from '../image/icon/email_candidate_profile_ic.svg'
import emailICAction from '../image/icon/email_candidate.svg'
import chat from '../image/icon/chat_candidate_profile.svg'
import video from '../image/icon/video_candidate_profile.svg'
import cw from '../image/icon/cw_ic.svg'
import downloadcw from '../image/icon/download_ic.svg'
import fetchData, {postData} from "../hook/http";
import {getEnv} from "@urecruits/api";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
	initialStateManageCandidateScreen,
	setDataCandidateScreenProfile, setDataCandidateScreenProfileExtra, setDownloadedCandidateScreenProfile
} from "../store/reducers/candidateProfileScreen";
import {store, useTypedSelector} from "../store";
import {ICandidateScreenInfo} from "../types/redux/candidate-screen";
import {transformDate} from "../utils/transformDate";
import {ManageCandidateEnumsButtons} from "../enums/manage-candidate/ManageCandidateEnums";

const {API_RECRUITMENT} = getEnv()
const getDataFunc = state => state.candidate_screen.info
const getDataFuncExtra = state => state.candidate_screen.extra
const getDataFuncDownload = state => state.candidate_screen.downloaded
const ManageCandidateProfileScreen = () => {
	const [display, setDisplay] = useState(ManageCandidateEnumsButtons.Candidate);

	const params = useParams()
const navigate=useNavigate()
	useLayoutEffect(() => {
		fetchData(`${API_RECRUITMENT}/api/candidate/public-candidate/${params.id}`).then(res => {
			fetchData(`${API_RECRUITMENT}/api/candidate/all/?limit=3&offset=0
				${res.skills.filter(x => x.name).map(item => `&skills=${item.name}`).join('')}
				&sortType=DESC
				&sortBy=id`).then(res2 => {
				store.dispatch(setDataCandidateScreenProfile(transformData(res)))
				store.dispatch(setDataCandidateScreenProfileExtra(res2.rows.filter(x => x.id === res.id).map(y => {
					return {
						name: `${y.user.firstname} ${y.user.middlename || ''} ${y.user.lastname}`,
						avatar: y.user.avatar,
						location: `${y.location?.city}, ${y.location.state}`,
						skills: y.skills,
						experienceYears: `${y.experience} years`,
						id:y.id
					}
				})))
				store.dispatch(setDownloadedCandidateScreenProfile(true))
			})
		},()=>{
			navigate('/recruitment/candidate-profile')
		})

		return () => {
			store.dispatch(setDataCandidateScreenProfile(initialStateManageCandidateScreen))
			store.dispatch(setDownloadedCandidateScreenProfile(false))
			store.dispatch(setDataCandidateScreenProfileExtra([]))
		}
	}, [params.id]);
	const {name} = useTypedSelector(getDataFunc)

	return <section>
		<div className='table-screen-top'>
			<div className='table-screen-top__wrapper' style={{flexDirection: 'column', alignItems: 'flex-start'}}>
				<h2 style={{marginBottom: '20px'}}>Candidate Profile</h2>
				<div className='candidate-profile-view__buttons-top'>
					<button
						className={`candidate-profile-view__buttons-top__item ${display === ManageCandidateEnumsButtons.Candidate ? 'active' : ''}`}
						onClick={() => setDisplay(ManageCandidateEnumsButtons.Candidate)}>{ManageCandidateEnumsButtons.Candidate}</button>
					<button
						className={`candidate-profile-view__buttons-top__item ${display === ManageCandidateEnumsButtons.Experience ? 'active' : ''}`}
						onClick={() => setDisplay(ManageCandidateEnumsButtons.Experience)}>{ManageCandidateEnumsButtons.Experience}</button>
					<button
						className={`candidate-profile-view__buttons-top__item ${display === ManageCandidateEnumsButtons.Educational ? 'active' : ''}`}
						onClick={() => setDisplay(ManageCandidateEnumsButtons.Educational)}>{ManageCandidateEnumsButtons.Educational}</button>
					<button
						className={`candidate-profile-view__buttons-top__item ${display === ManageCandidateEnumsButtons.Also ? 'active' : ''}`}
						onClick={() => setDisplay(ManageCandidateEnumsButtons.Also)}>{ManageCandidateEnumsButtons.Also}</button>
				</div>
			</div>
		</div>
		<div className='candidate-profile-view'>
			{
				name && <div
            className={`candidate-profile-view__content ${display === ManageCandidateEnumsButtons.Also ? 'disabled' : ""}`}>
            <Information display={display}/>
            <Experience display={display}/>
            <Educational display={display}/>
        </div>
			}
			<Extra display={display}/>
		</div>
	</section>
}

export default memo(ManageCandidateProfileScreen)

const Information = memo(({display}: { display: string }) => {
	const {
		name,
		avatar,
		degree,
		experienceYears,
		location,
		email,
		phone,
		skills,
		position
	} = useTypedSelector(getDataFunc)

	return <div
		className={`candidate-profile-view__content__item candidate-profile-view__info ${display === ManageCandidateEnumsButtons.Candidate ? 'active' : ''}`}>
		<div className='candidate-profile-view__info__top'>
			<h5 className='candidate-profile-view__info__title'>{name}</h5>
		</div>
		<div className='candidate-info'>
			<img src={avatar || defaultAvatar} alt='avatar' className='candidate-info__avatar'/>
			<div className='candidate-info__content'>
				<p className='candidate-info__content__title'>{position}</p>
				<div className='candidate-profile-view__row'>
					{
						[
							{title: degree, span: ''},
							{title: experienceYears, span: ''},
							{title: location, span: ''}].map(
							(value, index) => {
								return value.title && <DetailsRow key={index} item={value}/>
							}
						)
					}
				</div>
				<div className='candidate-info__contacts'>
					<p className='candidate-info__contacts__item'>
						<img src={emailIC} alt='email' className='candidate-info__contacts__image'/>
						{email}
					</p>
					<p className='candidate-info__contacts__item'>
						<img src={phoneIC} alt='email' className='candidate-info__contacts__image'/>
						{phone}
					</p>
				</div>
				<div className='candidate-info__actions'>
					<DownloadCV/>
					<button className='candidate-info__actions__item'>
						<img src={chat} alt='action' className='candidate-info__actions__image'/>
					</button>
					<button className='candidate-info__actions__item'>
						<img src={video} alt='action' className='candidate-info__actions__image'/>
					</button>
					<button className='candidate-info__actions__item'>
						<img src={emailICAction} alt='action' className='candidate-info__actions__image'/>
					</button>
				</div>
			</div>
		</div>
		{
			skills.filter(x => x.name).length > 0 && <div className='candidate-profile-view__skills'>
				{
					skills.filter(x => x.name).map((item, index) => {
							return <p key={index} className='candidate-profile-view__skills__item'>
								{item.name}
							</p>
						}
					)
				}
      </div>
		}
	</div>
})
const Extra = memo(({display}: { display: string }) => {
	const array = useTypedSelector(getDataFuncExtra)
	const loaded = useTypedSelector(getDataFuncDownload)

	return loaded&&<div
		className={`candidate-profile-view__content__item candidate-profile-view__extra ${display === ManageCandidateEnumsButtons.Also ? 'active' : ''}`}>
		<h5 className='candidate-profile-view__extra__title'>Also match to this candidate</h5>
		<div className='candidate-profile-view__extra__list'>
			{
				array.length===0&&<p>There no matched candidates</p>
			}
			{
				array.map((item, index) => {
					return <ExtraItem item={item} key={index}/>
				})
			}
		</div>
	</div>
})
const ExtraItem = memo(({item}: { item: any }) => {
	return <Link className='candidate-extra' to={`/recruitment/candidate-profile/${item.id}`}>
		<div className='candidate-extra__top'>
			<img src={item.avatar||defaultAvatar} alt='avatar' className='candidate-extra__top__avatar'/>
			<div className='candidate-extra__info'>
				<p className='candidate-extra__info__title'>{item.name}</p>
				<div className='candidate-profile-view__row nowrap'>
					{
						[
							{title: item.experienceYears, span: ''},
							{title: item.location, span: ''}].map(
							(value, index) => {
								return value.title && <DetailsRow key={index} item={value}/>
							}
						)
					}
				</div>
			</div>
		</div>
		<div className='candidate-extra__skills'>
			<div className='candidate-profile-view__skills nowrap'>
				{
					item.skills.filter(x => x.name).map((item, index) => {
							return <p key={index} className='candidate-profile-view__skills__item'>
								{item.name}
							</p>
						}
					)
				}
			</div>
		</div>
	</Link>
})
const Experience = memo(({display}: { display: string }) => {
	const {experience} = useTypedSelector(getDataFunc)
	return experience.filter(x => x.companyName).length > 0 &&
      <div
          className={`candidate-profile-view__content__item candidate-profile-view__details ${display === ManageCandidateEnumsButtons.Experience ? 'active' : ''}`}>
          <h5 className='candidate-profile-view__details__title'>Experience Details</h5>
				{
					experience.map((item, index) => {
						return <DetailsBlock key={index} item={item.position} array={[{title: item.companyName, span: ''}, {
							title: item.location,
							span: ''
						}, {title: `${transformDate(item.start)} - ${item.end&&!item.present ? transformDate(item.end) : 'Present'}`, span: ''}]}/>
					})
				}
      </div>
})
const Educational = memo(({display}: { display: string }) => {
	const {educational} = useTypedSelector(getDataFunc)

	return educational.filter(x => x.universityName).length > 0 &&
      <div
          className={`candidate-profile-view__content__item candidate-profile-view__details ${display === ManageCandidateEnumsButtons.Educational ? 'active' : ''}`}>
          <h5 className='candidate-profile-view__details__title'>Education Details</h5>
				{
					educational.map((item, index) => item.universityName &&
              <div key={index} className='candidate-profile-view__details__wrap'>
                  <div>
                      <DetailsBlock item={[item.universityName]} array={
												[{
													title: `${transformDate(item.coursePeriodStart)} - ${item.coursePeriodEnd&&!item.present?transformDate(item.coursePeriodEnd):'This credential does not expire'}`,
													span: ''
												},
													{title: item.gpa ? item.gpa + '%' : '', span: ''}
												]}
                      />
                  </div>
                  <div className='candidate-profile-view__details__bottom'>
                      <div className='candidate-profile-view__details__bottom-item'>
                          <h6 className='candidate-profile-view__details__bottom-title'>Course</h6>
                          <p className='candidate-profile-view__details__bottom-desc'>{item.courseName}</p>
                      </div>
                      <div className='candidate-profile-view__details__bottom-item'>
                          <h6 className='candidate-profile-view__details__bottom-title'>Specialization</h6>
                          <p className='candidate-profile-view__details__bottom-desc'>{item.specification}</p>
                      </div>
                  </div>
              </div>
					)
				}
      </div>
})
const DownloadCV = memo(() => {
	const {cvKey, cvName} = useTypedSelector(getDataFunc)
	const [link, setLink] = useState('');
	const onDownloadHandler = async () => {
		await postData(`${API_RECRUITMENT}/api/file/get-private`, {key: cvKey}).then(res => setLink(res))
	}
	const showCV = () => {
		window.open(link, '_blank')
	}
	return cvKey&&<button onClick={link === '' ? onDownloadHandler : showCV} className='candidate-info__actions__item'>
		<img src={link === '' ? downloadcw : cw} alt='action' className='candidate-info__actions__image'/>
		<p style={{minWidth: '110px'}}>{link === '' ? 'Download CV' : 'Look at CV'}</p>
	</button>
})
const transformData = (data: any): ICandidateScreenInfo => {
	console.error(data)
	return {
		name: `${data.user.firstname} ${data.user.middlename || ''} ${data.user.lastname}`,
		avatar: data.user.avatar,
		degree: data.degree,
		experienceYears: `${data.experience} years`,
		location: data.location?.city?`${data.location?.city}, ${data.location?.state}`:'',
		email: data.user.email,
		phone: data.user.phone,
		skills: data.skills,
		experience: data.experiences,
		educational: data.educations,
		position: data.position?.label,
		cvKey: data.cvKey,
		cvName: data.cvName
	}
}