import { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import defaultUser from "../../image/temp-user.png";
import plusIcon from '../../image/icon/plus_ic.svg';
import { getEnv } from "@urecruits/api";
import { Skeleton, Stack } from "@mui/material";
import fetchData from "../../hook/http";
import { DashboardEmptyDataComponent, AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_ASSESSMENT, API_RECRUITMENT } = getEnv();
const token: string = localStorage.getItem("token");
const config = {
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${token}`,
	}
};


const TeamMembers = ({ position }) => {
	const [data, setData] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [isEmptyData, setIsEmptyData] = useState(false)
	const { checkUserPermission } = useHasPermission();

	useEffect(() => {
		async function getInfo() {
			try {
				await fetchData(`${API_RECRUITMENT}/api/company/members?limit=20&offset=0`).then(data => {
					const members = data?.rows
					if (!members?.length) {
						setIsEmptyData(true)
					} else {
						setData(members);
					}
					setIsLoading(false)
				})
			} catch (error) {
				console.log(error)
				setIsEmptyData(true)
				setIsLoading(false)
			}
		}
		checkUserPermission('team-members', 'view') && getInfo().then()
	}, [checkUserPermission('team-members', 'view')])

	return (
		<div className={`dashboard-list ${position}`}>
			<div className="dashboard-list__head">
				<p className="dashboard-list__head__headline">Team Members</p>
				<div className="dashboard-list__links">
					<AuthGuard module='team-members' permission={'view'} >
						<Link to="/recruitment/manage-team-members" className="dashboard-list__head__link">View all</Link>
					</AuthGuard>
					<AuthGuard module='team-members' permission={'add'} >
						<Link to="/recruitment/manage-team-members/add-new">
							<img src={plusIcon} alt="Add Domain Assessment Icon" />
						</Link>
					</AuthGuard>
				</div>
			</div>
			<AuthGuard module='team-members' permission={'view'} >
				{
					isLoading && <Stack height={"100%"}>
						<Skeleton animation="wave" />
					</Stack>
				}
				{isEmptyData ? <DashboardEmptyDataComponent />
					:
					<div className="dashboard-list__list">
						{data.length > 0 &&
							data.map(offer => {
								return <ShowListItem offer={offer} key={offer.id} />
							})
						}
					</div>
				}
			</AuthGuard>
		</div>
	);
};

export default memo(TeamMembers);

const ShowListItem = ({ offer }) => {

	const navigate = useNavigate()
	const date = new Date(offer.createdAt).toString()

	return <div className="dashboard-list__item" >
		<div className="dashboard-list__item__inner">
			<div className="dashboard-list__item__top">
				<img src={offer.user?.avatar || defaultUser} alt="" className="dashboard-list__item__avatar" />
				<p className="dashboard-list__item__username">{(offer.user?.firstname || "") + " " + (offer.user?.lastname || "")}</p>
				<p className={`dashboard-list__item__status ${offer.profileStatus === "Active" ? "approved" : "pending"}`}>{offer.profileStatus || 'Pending'}</p>
			</div>
			<div className="dashboard-list__item__bottom">
				{/* {roundData && 
                <p className="dashboard-list__item__dot">{roundData.title}</p>
            } */}
				<p className="dashboard-list__item__dot">{offer.position?.label || ""}</p>
				<p className="dashboard-list__item__text">{offer.department}</p>
			</div>
		</div>
		{/* <div className="dashboard-list__item__arrow" onClick={()=>navigate("/recruitment/manage-team-members")}>
		<ArrowRight/>
	</div> */}
	</div>
}

// const ArrowRight = memo(() => {

// 	return (
// 		<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
// 			<path d="M3 1L7 5L3 9" stroke="#B0BBCB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
// 		</svg>
// 	);
// });