import {memo, useEffect, useState} from "react";
import {store, useTypedSelector} from "../../../store";
import AsyncSelect from "react-select/async";
import Option from "../../Global/SelectOptions";
import {getEnv, selectSearchFuncLocation} from "@urecruits/api";
import {selectCustomStyle} from "../../../styles/selectCustomStyle";
import {
	setEducationalFilterDetailViewJob,
	setExperienceFilterDetailViewJob,
	setLocationFilterDetailViewJob, setPercentFilterDetailViewJob, setSalaryYearFilterDetailViewJob, setSkillsFilterDetailViewJob
} from "../../../store/reducers/jobDetailViewReducerMatched";
import {DetailViewJobFilters} from "../../../enums/detail-view-job/detail-view-job-matched";

import {
	CustomRangeComponent,
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import {educationOption} from "../../../utils/constants";
import MultiSelect from "../../Global/MultySelectSkills/MultiSelect";
import axios from "axios";
const filterSkillFunc=state=>state.jobs_matched.filters.skills
const getFiltersFunc = (state) => state.jobs_matched.filters
const dispatchSkills=(value)=>store.dispatch(setSkillsFilterDetailViewJob(value))
const MatchedJobFilter = ({activeTab}) => {
	const filter = useTypedSelector(getFiltersFunc)
	const [defaultSkills, setDefaultSkills] = useState([]);
	const {API_RECRUITMENT} = getEnv()

	useEffect(() => {
		const func = async () => {
			try {
				await axios.get(`${API_RECRUITMENT}/api/skills/`).then(res => setDefaultSkills(res.data))
			} catch (e) {
				console.error('Fetch error', e)
			}
		}
		func().then()
	}, []);

	return <>
		{
			activeTab === DetailViewJobFilters.Location &&
      <div className='table-filter-popup__right__content'>
          <AsyncSelect
              isMulti
              components={{
								Option,
							}}
              cacheOptions
              loadOptions={(inputValue) =>
								inputValue.length > 0 ? selectSearchFuncLocation(inputValue) : selectSearchFuncLocation('')
							}
              defaultOptions
              value={filter.location}
              onChange={(option: any) => {
								store.dispatch(setLocationFilterDetailViewJob(option))
							}}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              placeholder='Search by job location'
              styles={selectCustomStyle}
              id="locationSelect"
              instanceId="locationSelect"
          />
      </div>
		}
		{
			activeTab === DetailViewJobFilters.Experience && <div className='table-filter-popup__right__content'>
					<label className='table__range__label'>Experience<span>(years)</span></label>
          <CustomRangeComponent rangeValue={filter.experience} max={50} min={0} setData={(state) => {
						store.dispatch(setExperienceFilterDetailViewJob(state))
					}}/>
      </div>
		}
		{
			activeTab === DetailViewJobFilters.Salary && <div className='table-filter-popup__right__content'>
          <label className='table__range__label'>Salary Range<span>(per year)</span></label>
          <CustomRangeComponent rangeValue={filter.salary_year} max={1000000} min={0} dollar={true} setData={(state) => {
						store.dispatch(setSalaryYearFilterDetailViewJob(state))
					}}/>
      </div>
		}
		{
			activeTab===DetailViewJobFilters.Education&&<div className='table-filter-popup__right__content'>
				{
					<ul className='filters-popup-mtm-buttons-list'>
						{
							educationOption.map(item => {
								return <li
									className={`${filter.education.find(x => x === item.value) ? 'filters-popup-mtm-buttons-list__item active' : 'filters-popup-mtm-buttons-list__item'}`}
									key={item.value}
									onClick={() => store.dispatch(setEducationalFilterDetailViewJob(filter.education.find(x => x === item.value) ? filter.education.filter(x => x !== item.value) : [...filter.education, item.value]))}
								>
									{item.value}
								</li>
							})
						}
					</ul>
				}
      </div>
		}
		{
			activeTab===DetailViewJobFilters.Percentage&&<div className='table-filter-popup__right__content'>
          <label className='table__range__label'>Percentage of matching<span>(%)</span></label>
          <CustomRangeComponent rangeValue={filter.percentage} max={100} min={0} setData={(state) => {
						store.dispatch(setPercentFilterDetailViewJob(state))
					}}/>
      </div>
		}
		{
			activeTab===DetailViewJobFilters.Key_Skills&&<MultiSelect defaultSkills={defaultSkills} getReduxDataFunc={filterSkillFunc} dispatchFunc={dispatchSkills}/>
		}
	</>
}
export default memo(MatchedJobFilter)
