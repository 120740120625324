import { useEffect, useCallback, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { TableCardViewComp, AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { IColumns } from "@ucrecruits/globalstyle/types/table-types";
import {
  InterviewsCandidatesTableHeader,
  RoundStatus
} from "../enums";
import fetchData from "../hook/http";
import { Link } from "react-router-dom";
import { getEnv } from "@urecruits/api";
import { store, useTypedSelector } from "../store";
import {
  setCurrentPage,
  setLimit,
  setSearchValue,
  setSortByFilter,
  setSortTypeFilter,
  setTotalCount,
  defaultFilters,
  setOrderColumns,
  setInterviewType,
} from "../store/reducers/interviewsCandidatesReducer";
import useSnackbar from "../hook/useSnackbar";
import { useNavigate } from "react-router-dom";
import { findActiveInterview, findActiveRound } from "../utils";
import { CandidatesFilters } from "../components/Interviews";
import ScheduleMeeting from "../components/CandidateScoreboard/ScheduleMeeting";
import CalendarPopup from "../components/CalendarPopup/CalendarPopup";
const starIcon = require("../image/icon/star_ic.svg");
const eyeIcon = require('../image/icon/eye-on.svg');
const videoIcon = require("../image/icon/video_candidate_profile.svg");
const { API_RECRUITMENT } = getEnv();

type ICandidateHeaderState = {
    jobTitle: string;
    jobLocation: string;
  };

const getTable = (store) => store.interviews_candidates;
const getTableFilters = (store) => store.interviews_candidates.filters;
const getTableFilterData = (store) => store.interviews_candidates.filterInfo;
const getCalendarFunc = state => state.candidate_scoreboard.calendarPopup

export const InterviewsCandidates = () => {
  const table = useTypedSelector(getTable);
  const selectedInterviewType = table.filters.interviewType;
  const [isEmptyFilter, setIsEmptyFilter] = useState<boolean>(false);
  const [isFilterSubmit, setIsFilterSubmit] = useState(false);
  const { ErrorElement, APIExecutor } = useSnackbar();
  const [meetingData, setMeetingData] = useState({
            recruiterId : null,
            candidateId : null,
            jobId : null,
            roundName :null,
            eventId : null,
            eventTitle : null,
            interviewers :null,
  });
  const navigate = useNavigate();
  const { checkUserPermission } = useHasPermission();

  const { jobId } = useParams();
  const [jobTitle, setJobTitle] = useState("");
  const location = useLocation();
  const state = location?.state as ICandidateHeaderState;
  const { jobTitle: routeJobTitle, jobLocation: routeJobLocation } =
    state ?? {};
  const [isScheduleMeeting, setIsScheduleMeeting] = useState(false);
  const {open} = useTypedSelector(getCalendarFunc)

    useEffect(() => {
        const getJobTitle = async () => {
          const { title } = await APIExecutor(() =>
            fetchData(`${API_RECRUITMENT}/api/job/${jobId}`)
          );
          setJobTitle(title);
        };
    
        if (!routeJobTitle && checkUserPermission('job-post', 'view')) {
          getJobTitle();
        }
      }, [checkUserPermission('job-post', 'view')]);   

  const setSearchCallback = useCallback((value) => {
    store.dispatch(setSearchValue(value));
    store.dispatch(setCurrentPage(1));
  }, []);

  const setCurrentPageCallback = useCallback((value) => {
    store.dispatch(setCurrentPage(value));
  }, []);

  const FilterItems = (state) => {
    return <CandidatesFilters activeTab={state}/>;
  };

  const createAPIString = () => {
    const filters = table.filters;
    const pagination = table.pagination;
    return `${API_RECRUITMENT}/api/interviews/candidates/${jobId}?limit=${
      pagination.limit
    }&offset=${
      (pagination.currentPage - 1) * pagination.limit
    }${
      filters.searchValue ? "&search=" + filters.searchValue : ""
    }${filters.sortBy ? "&sortBy=" + filters.sortBy : ""}${
      filters.sortType ? "&sortType=" + filters.sortType : ""
    }`;
  };

  const resetFilters = (pagination) => {
    store.dispatch(setSortByFilter(defaultFilters.sortBy));
    store.dispatch(setSortTypeFilter(defaultFilters.sortType));
    store.dispatch(setSearchValue(defaultFilters.searchValue));
    setIsFilterSubmit(true);
  };

  const columnCallbacks = {
    candidateName: (cellValue, rowData) => {
     const candidateName = rowData?.user ? rowData?.user?.firstname + " " + rowData?.user?.lastname : '-'  
      return (
        <p className="interviews__candidates__table__text">
          {candidateName}
        </p>
      );
    },
    scoresPercentage: (cellValue, rowData) => {
      const scoresPercentage = rowData.totalScores;
      return <p className="interviews__candidates__table__text">{`${scoresPercentage}%`}</p>;
    },
    interviewerComments: (cellValue, rowData) => {
     
      return <p className="interviews__candidates__table__text">{"Est mauris parturient augue..."}</p>;
    },
    scoreBoard :(cellValue, rowData)=> {
        const to = `/recruitment/job/${rowData.jobId}/candidate-scoreboard/${rowData.userId}`;
        return(<Link className="interviews__candidates__table__link" to={to}>
        <img src={starIcon} alt="start_icon" />
        <span>Candidate Scoreboard</span>
      </Link>);
    },
    workflowStatus: {
      large:(cellValue, rowData) => {
      const activeRound = Array.isArray(rowData?.round?.rounds) ? findActiveRound(rowData?.round?.rounds) : null;
         return (
           <p
             className={`${activeRound?.title ? 'table__status-block table__status-block--yellow':''}`}
           >
             {activeRound?.title ? activeRound?.title : "-"}
           </p>
         );
       },
      small:(cellValue, rowData) => {
        const activeRound = Array.isArray(rowData?.round?.rounds) ? findActiveRound(rowData?.round?.rounds) : null;
           return (
             <p
             className={`${activeRound?.title ? 'table__status-block table__status-block--yellow interviews__candidates__table__cell-fit-content':''}`}
             >
               {activeRound?.title ? activeRound?.title : "-"}
             </p>
           );
         },
        },
    roundStatus :{
      large:(cellValue, rowData)=>{
        const activeRound = Array.isArray(rowData?.round?.rounds) ? findActiveRound(rowData?.round?.rounds) : null;
        const roundStatus = activeRound?.status;
        return(
            <p className={`${roundStatus ? `table__status-block ${getRoundStatusStyle(roundStatus)}` : ""}`}>{roundStatus || "-"}</p>
        )
      },
      small :(cellValue, rowData)=>{
        const activeRound = Array.isArray(rowData?.round?.rounds) ? findActiveRound(rowData?.round?.rounds) : null;
        const roundStatus = activeRound?.status;
        return(
          <p className={`${roundStatus ? `table__status-block  ${getRoundStatusStyle(roundStatus)} interviews__candidates__table__status-block` : ""}`}>{roundStatus || "-"}</p>
      )
      }
    },
    ScheduleMeetingAction: (rowData) => {
      const activeInterview = Array.isArray(rowData?.round?.rounds) ? findActiveInterview(rowData?.round?.rounds) : null;
      const roundStatus = activeInterview?.status;

      if(!roundStatus){
         return null;
      }

      if(roundStatus === RoundStatus.IN_PROGRESS){
        const eventId = activeInterview?.eventId;
        const eventTitle = activeInterview?.eventTitle;
        const interviewers = activeInterview?.interviewers;
        const startDate = activeInterview?.startDate;
        const endDate = activeInterview?.endDate;
        let actionText = 'Schedule Meeting';
        if(eventId) actionText = 'Reschedule Meeting';

        return {
        svg: <div className="interviews__candidates__table__action"><img src={videoIcon} alt="video icon" /><span>{actionText}</span></div>,
        title: "",
        onClick: (row, event) => {
          const meetingData ={
            recruiterId : row.job.authorId,
            candidateId : row.userId,
            jobId : jobId,
            roundName : activeInterview?.title,
            eventId : eventId ?? null,
            eventTitle : eventTitle ?? null,
            interviewers : interviewers ?? null,
          }        
          setMeetingData(meetingData);
          setIsScheduleMeeting(true);
        },
        className: "table-mobile__item__action-button--half",
      }
      }
    }
  }  
  const columns: Array<IColumns> = [
    {
      headerName: InterviewsCandidatesTableHeader.CANDIDATE_NAME,
      field: "candidate name",
      pinnable: true,
      renderCell: columnCallbacks.candidateName,
      mobileRenderCell: columnCallbacks.candidateName,
      visibility: true,
      isMobileTitle: true,
      className: "",
    },
    {
      headerName: InterviewsCandidatesTableHeader.SCORES_PERCENTAGE,
      field: "scores percentage",
      renderCell: columnCallbacks.scoresPercentage,
      mobileRenderCell: columnCallbacks.scoresPercentage,
      visibility: true,
      className: "",
    },
    {
      headerName: InterviewsCandidatesTableHeader.INTERVIEWER_COMMENTS,
      field: "interviewer comments",
      renderCell: columnCallbacks.interviewerComments,
      mobileRenderCell: columnCallbacks.interviewerComments,
      visibility: true,
      className: "",
    },
    {
      headerName: InterviewsCandidatesTableHeader.SCOREBOARD,
      field: "score board",
      renderCell: columnCallbacks.scoreBoard,
      mobileRenderCell: columnCallbacks.scoreBoard,
      visibility: true,
      className: "",
    },
    {
        headerName: InterviewsCandidatesTableHeader.WORKFLOW_STATUS,
        field: "workflow status",
        renderCell: columnCallbacks.workflowStatus.large,
        mobileRenderCell: columnCallbacks.workflowStatus.small,
        visibility: true,
        className: "interviews__candidates__table__cell-max-content",
      },
      {
        headerName: InterviewsCandidatesTableHeader.ROUND_STATUS,
        field: "round status",
        renderCell: columnCallbacks.roundStatus.large,
        mobileRenderCell: columnCallbacks.roundStatus.small,
        visibility: true,
        className: "",
      },
      {...(checkUserPermission('recruiter') ? {
        headerName: "Actions",
        field: "actions",
        setActions: [
          {
            getAction: columnCallbacks.ScheduleMeetingAction
          },
        ],
        visibility: true,
      } :null)},
  ].filter(element => !!Object.keys(element).length);

  const setInterviewTypeCallback = (type: string) => {
    store.dispatch(setInterviewType(type));
  };

  return (
    <>
    <AuthGuard module='job-post' permission='view'>
    <ErrorElement/>
      <div className="interviews">
        <div className="interviews__candidates__head">
          <ul className="interviews__candidates__head__breadcrumbs">
            <li className="interviews__candidates__head__breadcrumbs__item">
              <Link
                to="/recruitment/interviews/jobs"
                className="interviews__candidates__head__breadcrumbs__link"
              >
                Interviews
              </Link>
            </li>
            <li className="interviews__candidates__head__breadcrumbs__item">
              <p className="interviews__candidates__head__breadcrumbs__link">
                {routeJobTitle ?? jobTitle}
              </p>
            </li>
          </ul>
          <p className="interviews__candidates__head__title">
            {routeJobTitle ?? jobTitle}{" "}
          </p>
          <p className="users-offers__head__location">
            {routeJobLocation || ""}
          </p>
        </div>

      <TableCardViewComp
      tableViewType="table"
      columns={columns}
      listAPI={createAPIString()}
      searchField={{
        searchValue: table.filters.searchValue,
        setSearchValue: setSearchCallback,
        placeholder: "Search by name",
      }}
      pagination={{
        limit: table.pagination.limit,
        currentPage: table.pagination.currentPage,
        totalCount: table.pagination.totalCount,
        setTotalCount: (count) => {
          store.dispatch(setTotalCount(count));
        },
        setCurrentPage: setCurrentPageCallback,
        setLimit: (value) => {
          store.dispatch(setLimit(value));
        },
        filters: table.filters,
      }}
      emptyTable={{
        title:"Table is empty",
        desc :"There are no users yet",
        buttonText:"Go to Jobs",
        handler:()=>navigate(`recruitment/interviews/jobs`)
      }}
      rearrangeColumns={{ setColumnOrder: setOrderColumns }}
      storeName="interviews_candidates"
      store={store}
      useTypedSelector={useTypedSelector}
    />
    </div>
    {isScheduleMeeting && <ScheduleMeeting meetingData={meetingData} setIsScheduleMeeting={setIsScheduleMeeting}/>}
    {open && <CalendarPopup/>}
    </AuthGuard>
    </>
  );
};

const getRoundStatusStyle = (status)=>{
   switch(status){
     case RoundStatus.PENDING : return 'table__status-block--orange';
     case RoundStatus.IN_PROGRESS: return 'table__status-block--yellow';
     case RoundStatus.COMPLETED : return 'table__status-block--green';
     case RoundStatus.REJECTED : return 'table__status-block--red';
   } 
}
