import {store, useTypedSelector} from "../../../store";
import {JobsOffersTableTabsEnums} from "../../../enums/jobsOffers/JobsOffersEnums";
import AsyncSelect from "react-select/async";
import Option from "../../Global/SelectOptions";
import {selectSearchFuncLocation} from "@urecruits/api";
import {selectCustomStyle} from "../../../styles/selectCustomStyle";
import {setLocationFilterJobsOffers} from "../../../store/reducers/jobsOffersReducer";
import {memo} from "react";

const getFiltersFunc = state => state.offers_jobs.filters;

const FilterItemsJobsOffers = ({activeTab}) => {
	const filter = useTypedSelector(getFiltersFunc);

	return (
		<>
			{
				activeTab === JobsOffersTableTabsEnums.JOB_LOCATION && (
					<div className="table-filter-popup__right__content">
						<AsyncSelect
							isMulti
							components={{
								Option,
							}}
							cacheOptions
							loadOptions={(inputValue) =>
								inputValue.length > 0 ? selectSearchFuncLocation(inputValue) : selectSearchFuncLocation("")
							}
							defaultOptions
							value={filter.location}
							onChange={(option: any) => {
								store.dispatch(setLocationFilterJobsOffers(option));
							}}
							hideSelectedOptions={false}
							closeMenuOnSelect={false}
							placeholder="Search by job location"
							styles={selectCustomStyle}
							id="locationSelect"
							instanceId="locationSelect"
						/>
					</div>
				)
			}
		</>
	);
};

export default memo(FilterItemsJobsOffers);