import {memo, useCallback, useLayoutEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
	PaginationComponent,
	SearchFieldComponent,
	TheadItemComponent
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import {CSSTransition} from "react-transition-group";
import useClickOutside from "../hook/useClickOutside";
import {ManageCandidateTableOrder} from "../components/HOCs/OrderPopupHOCs";
import {store, useTypedSelector} from "../store";
import {sortingFunc} from "../utils/sortingFuncForTables";
import TopFilterButton from "../components/Global/table/TopFilterButton";
import MemoOrderSVG from "../components/Global/table/MemoOrderSVG";
import FilterWrapper from "../components/Global/table/FilterWrapper";
import axios from "axios";
import {getEnv} from "@urecruits/api";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import TableEmpty from "../components/Global/table/TableEmpty";

import {
	defaultFiltersManageCandidate,
	setCurrentPageManageCandidate,
	setEducationalManageCandidate,
	setEmptySearchManageCandidate,
	setEmptyTableManageCandidate,
	setExperienceManageCandidate,
	setLimitManageCandidate,
	setLocationManageCandidate,
	setSalaryYearManageCandidate,
	setSearchValueManageCandidate,
	setSkillsFilterManageCandidate,
	setSortByManageCandidate,
	setSortTypeManageCandidate,
	setTableItemsManageCandidate,
	setTotalCountManageCandidate
} from "../store/reducers/manageCandidate";
import FilterPopupManageCandidate from "../components/ManageCandidates/FilterPopupManageCandidate";
import {IManageCandidateTableItems} from "../types/redux/manage-candidate";
import ManageCandidatesTBody from "../components/ManageCandidates/ManageCandidatesTBody";
import ManageCandidateMobileTable from "../components/ManageCandidates/ManageCandidateMobileTable";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";

const {API_RECRUITMENT} = getEnv()
const token: string = localStorage.getItem('token')
const config = {
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
	},
}
const getFilters = state => state.manage_candidate.filters
const tableFunc = state => state.manage_candidate
const getFilterDataFunc = state => state.manage_candidate.filterInfo
const getLimitFunc = state => state.manage_candidate.pagination.limit
//TODO replace component
const FilterItems = state => <FilterPopupManageCandidate activeTab={state}/>
const FilterPopupInnerWrap = (setState) => {
	const filters = useTypedSelector(getFilters)
	const filtersData = useTypedSelector(getFilterDataFunc)
	const limit = useTypedSelector(getLimitFunc)
	return <FilterWrapper setFilterPopup={setState}
	                      resetFunc={resetFunc}
	                      filters={filters}
	                      filterData={filtersData}
	                      submitFunc={setManageCandidatesHandler}
	                      limit={limit}
	>
		{FilterItems}
	</FilterWrapper>
}
const ManageCandidatesScreen = () => {
	const [orderPopup, setOrderPopup] = useState(false);
	const [horizontalScrollState, setHorizontalScrollState] = useState(false);
	const navigate = useNavigate()
	const orderPopupRef = useRef<null | HTMLButtonElement>(null);
	const tableRef = useRef(null);

	const table = useTypedSelector(tableFunc)

	useLayoutEffect(() => {
		setManageCandidatesHandler(table.pagination.currentPage, table.pagination.limit, table.filters)
	}, []);

	useTableClickAndDragScroll(tableRef);
//TODO add filter array
	const filterArray = [
		table.filters.searchValue, table.filters.sortBy, table.filters.sortType, table.pagination.limit,
		table.filters.location, table.filters.education, table.filters.skills, table.pagination.experience,
		table.filters.salary_year
	]

	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchValueManageCandidate(value))
		setManageCandidatesHandler(1, table.pagination.limit, {...table.filters, searchValue: value})
	}, filterArray);

	const emptyTableCallback = useCallback(() => {
		navigate('/')
	}, [],);


	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeManageCandidate, setSortByManageCandidate, setManageCandidatesHandler), filterArray,);
	useClickOutside(orderPopupRef, setOrderPopup)
	return <section>
		<div className='table-screen-top'>
			<div className='table-screen-top__wrapper'>
				<h2>Manage Candidate Profile</h2>
			</div>
		</div>
		<div className="table__wrapper">
			<div className="table__top">
				<div className='manage-team__top__left'>
					<TopFilterButton>
						{
							FilterPopupInnerWrap
						}
					</TopFilterButton>
					<SearchFieldComponent searchValue={table.filters.searchValue} setSearchValue={setSearchCallback}
					                      placeholder={'Search candidates'}/>
				</div>
				<button className={`manage-team__top__svg ${orderPopup ? 'active' : ''}`} ref={orderPopupRef}>
					<MemoOrderSVG setState={setOrderPopup}/>
					{
						<CSSTransition in={orderPopup} timeout={300} classNames={'order-popup-mtm'} unmountOnExit mountOnEnter>
							<ManageCandidateTableOrder setOrderPopup={setOrderPopup}/>
						</CSSTransition>
					}
				</button>
			</div>
			{
				table.emptySearch || table.emptyTable ? (
						table.emptySearch ? <NoResultsSearch limit={table.pagination.limit} resetFunc={resetFunc}/>
							: <TableEmpty handler={emptyTableCallback} cta={'Go home'} title={'Table is empty'}
							              desc={'Candidates table is empty'}/>
					)
					:
					<>
						<table className='table' ref={tableRef} onScroll={(e: any) => {
							if (e.target.scrollLeft > 10 && !horizontalScrollState) setHorizontalScrollState(() => true)
							else if (e.target.scrollLeft < 10 && horizontalScrollState) setHorizontalScrollState(() => false)
						}}>
							<thead className="table__thead">
							<td
								className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} table__td--thead jobs-table__column__middle`}>
								<TheadItemComponent title={table.fixedTab.displayName} handler={null} dbName={''}/>
							</td>
							{
								table.tabFilter.map((item) => {
									return item.active &&
                      <td className={`table__td  table__td--thead jobs-table__column__default`}
                          key={item.id}>
                          <TheadItemComponent title={item.displayName} handler={item.dbName ? tdOrderCallback : null}
                                              dbName={item.dbName}/>
                      </td>
								})
							}

							</thead>
							<ManageCandidatesTBody horizontalScrollState={horizontalScrollState}/>
						</table>

						<div className='table-mobile'>
							{
								table.tableItems.map(item => {
									return <ManageCandidateMobileTable key={item.id} item={item}/>
								})
							}
						</div>
						<PaginationComponent
							limit={table.pagination.limit}
							currentPage={table.pagination.currentPage}
							totalCount={table.pagination.totalCount}
							setCurrentPage={setManageCandidatesHandler}
							setLimit={setLimitHandler}
							filters={table.filters}
						/>
					</>
			}
		</div>
	</section>
}
export default memo(ManageCandidatesScreen)


export const setManageCandidatesHandler = (page: number, limit: number, filters: any) => {
	store.dispatch(setCurrentPageManageCandidate(page))
	const getData = async () => {
		await axios(`${API_RECRUITMENT}/api/candidate/all/?limit=${limit}
		&offset=${(page - 1) * limit}
		&search=${encodeURIComponent(filters.searchValue)}
		${filters.education.map(item => `&degree=${item}`).join('')}
		${filters.location.map(item => `&locationId=${item.value}`).join('')}
		${filters.skills.map(item => `&skills=${item}`).join('')}
		&experienceMin=${filters.experience[0]}
		&experienceMax=${filters.experience[1]}
		&salaryYearMin=${filters.salary_year[0]}
		&salaryYearMax=${filters.salary_year[1]}
		&sortType=${filters.sortType}
		&sortBy=${filters.sortBy}`, config).then(res => {
			store.dispatch(setTotalCountManageCandidate(res.data.count))
			const items = transformArrayToTableData(res.data.rows)
			store.dispatch(setTableItemsManageCandidate(items))

			if (items.length === 0) {
				if (filters.searchValue === ''  &&
					filters.location.length===0 && filters.education.length === 0&&
					filters.skills.length===0 && filters.experience[0] === 0&&filters.experience[1] === 50&&
					 filters.salary_year[0] === 0&&filters.salary_year[1] === 1000000)
				{
					store.dispatch(setEmptyTableManageCandidate(true))
				} else {
					store.dispatch(setEmptySearchManageCandidate(true))
				}
			} else {
				store.dispatch(setEmptySearchManageCandidate(false))
				store.dispatch(setEmptyTableManageCandidate(false))
			}
		})
	}
	getData().then()
}
const setLimitHandler = (value: any) => {
	store.dispatch(setLimitManageCandidate(value))
}
const transformArrayToTableData = (array: any): Array<IManageCandidateTableItems> => {
	return array.map(item => {
		return {
			id: item.id,
			firstname: item.user?.firstname,
			middlename: item.user?.middlename,
			lastname: item.user?.lastname,
			avatar: item.user?.avatar,
			salary: item.preferencesExpectedCTC,
			experience: item.experience,
			location: item.location?.city?`${item.location?.city}, ${item.location?.state}`:'',
			email: item.user?.email,
			phone: item.user?.phone
		}
	})
}
const resetFunc = (pagination) => {
	store.dispatch(setSkillsFilterManageCandidate([]))
	store.dispatch(setEducationalManageCandidate([]))
	store.dispatch(setSalaryYearManageCandidate([0, 1000000]))
	store.dispatch(setExperienceManageCandidate([0,50]))
	store.dispatch(setLocationManageCandidate([]))
	store.dispatch(setSearchValueManageCandidate(''))
	store.dispatch(setSortByManageCandidate('id'))
	store.dispatch(setSortTypeManageCandidate('DESC'))
	setManageCandidatesHandler(1, pagination, defaultFiltersManageCandidate)
}