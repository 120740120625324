import {setOrderMTM} from "../../store/reducers/manageTeamMembersReducer";
import OrderPopupInner from "../Global/table/OrderPopup/OrderPopupInner";
import {setOrderJobs} from "../../store/reducers/jobsTableReducer";
import {setOrderDetailViewJobs} from "../../store/reducers/jobDetailViewReducerMatched";
import {setOrderManageCandidate} from "../../store/reducers/manageCandidate";
import {setOrderDomainAssessment} from "../../store/reducers/domainAssessmentReducer";
import {setOrderJobsOffers} from "../../store/reducers/jobsOffersReducer";
import {setOrderUsersOffers} from "../../store/reducers/usersOffersReducer";
import { setOrderManageAssignment } from "../../store/reducers/manageAssignmentReducer";

const setData = (WrappedComponent, setOrderFunc) => {
	return function (props) {
		const {stateFunc, dispatchFunc,fixedTabFunc} = setOrderFunc()
		return <WrappedComponent {...props} stateFunc={stateFunc} dispatchFunc={dispatchFunc} fixedTabFunc={fixedTabFunc} />
	}
}

export const MTMOrder = setData(OrderPopupInner, () => {
	return {
		stateFunc: store => store.team_members.userTabsFilter,
		dispatchFunc: setOrderMTM,
		fixedTabFunc: store => store.team_members.nameTab,
	};
});

export const OffersJobsOrder = setData(OrderPopupInner, () => {
	return {
		stateFunc: store => store.offers_jobs.tabFilter,
		dispatchFunc: setOrderJobsOffers,
		fixedTabFunc: store => store.offers_jobs.fixedTab,
	};
});

export const OffersUsersOrder = setData(OrderPopupInner, () => {
	return {
		stateFunc: store => store.offers_users.tabFilter,
		dispatchFunc: setOrderUsersOffers,
		fixedTabFunc: store => store.offers_users.fixedTab,
	};
});

export const JobsTableOrder = setData(OrderPopupInner, () => {
	return {
		stateFunc: store => store.jobs.tabFilter,
		dispatchFunc: setOrderJobs,
		fixedTabFunc: store => store.jobs.fixedTab,
	};
});
export const JobsDetailViewTableOrder = setData(OrderPopupInner, () => {
	return {
		stateFunc: store => store.jobs_matched.tabFilter,
		dispatchFunc: setOrderDetailViewJobs,
		fixedTabFunc:store =>store.jobs_matched.fixedTab
	}
})

export const ManageCandidateTableOrder = setData(OrderPopupInner, () => {
	return {
		stateFunc: store => store.manage_candidate.tabFilter,
		dispatchFunc: setOrderManageCandidate,
		fixedTabFunc:store =>store.manage_candidate.fixedTab
	}
})
export const DomainAssessmentTableOrder = setData(OrderPopupInner, () => {
	return {
		stateFunc: store => store.domain_assessment.tabFilter,
		dispatchFunc: setOrderDomainAssessment,
		fixedTabFunc:store =>store.domain_assessment.fixedTab
	}
})
export const ManageAssignmentTableOrder = setData(OrderPopupInner, () => {
	return {
		stateFunc: store => store.manage_assignment.tabFilter,
		dispatchFunc: setOrderManageAssignment,
		fixedTabFunc:store =>store.manage_assignment.fixedTab
	}
})
