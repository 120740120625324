import { memo, useCallback, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	PaginationComponent,
	SearchFieldComponent,
	TheadItemComponent
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { CSSTransition } from "react-transition-group";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import useClickOutside from "../hook/useClickOutside";
import { DomainAssessmentTableOrder } from "../components/HOCs/OrderPopupHOCs";
import { store, useTypedSelector } from "../store";
import { sortingFunc } from "../utils/sortingFuncForTables";
import TopFilterButton from "../components/Global/table/TopFilterButton";
import MemoOrderSVG from "../components/Global/table/MemoOrderSVG";
import FilterWrapper from "../components/Global/table/FilterWrapper";
import { getEnv } from "@urecruits/api";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import TableEmpty from "../components/Global/table/TableEmpty";
import {
	defaultFiltersDomainAssessmentScreen,
	setCurrentPageDomainAssessment,
	setDepartmentDomainAssessment,
	setEmptySearchDomainAssessment,
	setEmptyTableDomainAssessment,
	setIndustryDomainAssessment,
	setLimitDomainAssessment,
	setScoreFilterDomainAssessment,
	setSearchValueDomainAssessment,
	setSortByDomainAssessment, setSortTypeDomainAssessment, setTableItemsDomainAssessment, setTimeFilterDomainAssessment, setTotalCountDomainAssessment
} from "../store/reducers/domainAssessmentReducer";
import ManageDomainAssessmentTBody from "../components/DomainAssessment/ManageDomainAssessmentTBody";
import PopupWithInstruction from "../components/DomainAssessment/PopupWithInstruction";
import axios from "axios";
import FilterPopupManageDomainAssessment from "../components/DomainAssessment/FilterPopupManageDomainAssessment";
import { QuestionModal } from "../components/DomainAssessment/popup/modal";
import DeletePopup from "../components/DomainAssessment/popup/DeletePopup";
import ManageDomainMobileTable from "../components/DomainAssessment/ManageDomainMobileTable";
import { useHasPermission, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const {API_RECRUITMENT,API_ASSESSMENT} = getEnv()

const getFilters = state => state.domain_assessment.filters
const tableFunc = state => state.domain_assessment
const getFilterDataFunc = state => state.domain_assessment.filterInfo
const getLimitFunc = state => state.domain_assessment.pagination.limit
const getPopupWithInstruction = (state) => state.domain_assessment.popupWithInstruction;
const getPopupWithDelete = (state) => state.domain_assessment.popupWithDelete;
const getOpenPreview = (state) =>
  state.domain_assessment.openPreview;

//TODO replace component
const FilterItems = state => <FilterPopupManageDomainAssessment activeTab={state}/>
const FilterPopupInnerWrap = (setState) => {
	const filters = useTypedSelector(getFilters)
	const filtersData = useTypedSelector(getFilterDataFunc)
	const limit = useTypedSelector(getLimitFunc)
	return <FilterWrapper setFilterPopup={setState}
	                      resetFunc={resetFunc}
	                      filters={filters}
	                      filterData={filtersData}
	                      submitFunc={setDomainAssessmentScreenHandler}
	                      limit={limit}
	>
		{FilterItems}
	</FilterWrapper>
}
const DomainAssessmentScreen = () => {
	const {checkUserPermission} = useHasPermission();

	const popupWithInstruction = useTypedSelector(getPopupWithInstruction);
	const popupWithDelete = useTypedSelector(getPopupWithDelete);
	const [orderPopup, setOrderPopup] = useState(false);
	const [horizontalScrollState, setHorizontalScrollState] = useState(false);
	const navigate = useNavigate()
	const orderPopupRef = useRef<null | HTMLButtonElement>(null);
	const tableRef = useRef(null);
	const openPreview=useTypedSelector(getOpenPreview)
	const table = useTypedSelector(tableFunc)

	useLayoutEffect(() => {
		checkUserPermission('assessment','view') && setDomainAssessmentScreenHandler(table.pagination.currentPage, table.pagination.limit, table.filters)
	}, [popupWithInstruction?.open,popupWithDelete.open]);

//TODO add filter array
	const filterArray = [
		table.filters.searchValue, table.filters.sortBy, table.filters.sortType
	]

	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchValueDomainAssessment(value))
		setDomainAssessmentScreenHandler(1, table.pagination.limit, {...table.filters, searchValue: value})
	}, filterArray);
//TODO replace callback
	const emptyTableCallback = useCallback(() => {
		 navigate('/manage-domain')
	}, [],);
useTableClickAndDragScroll(tableRef)

	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeDomainAssessment, setSortByDomainAssessment, setDomainAssessmentScreenHandler), filterArray,);
	useClickOutside(orderPopupRef, setOrderPopup)
	return <section>
			{
			table.popupWithInstruction.open &&<PopupWithInstruction/>
			}
			{
			table.popupWithDelete.open &&<DeletePopup/>
			}
			{
			openPreview?.isOpen &&<QuestionModal/>
			}
		<div className='table-screen-top'>
			<div className='table-screen-top__wrapper'>
				<h2>Domain Assessments</h2>
			</div>
				<AuthGuard module='assessment' permission='add'>
					<button className='button--filled table-screen-top__button' onClick={() => ()=>navigate('/manage-domain')}>Add New</button>
				</AuthGuard>
		</div>
		<div className="table__wrapper">
			<div className="table__top">
				<div className='manage-team__top__left'>
				<AuthGuard module='assessment' permission='view'>
					<TopFilterButton>
						{
							FilterPopupInnerWrap
						}
					</TopFilterButton>
					<SearchFieldComponent searchValue={table.filters.searchValue} setSearchValue={setSearchCallback}
					                      placeholder={'Search domain assessment'}/>
				</AuthGuard>
				</div>
				<button className={`manage-team__top__svg ${orderPopup ? 'active' : ''}`} ref={orderPopupRef}>
					<MemoOrderSVG setState={setOrderPopup}/>
					{
						<CSSTransition in={orderPopup} timeout={300} classNames={'order-popup-mtm'} unmountOnExit mountOnEnter>
							<DomainAssessmentTableOrder setOrderPopup={setOrderPopup}/>
						</CSSTransition>
					}
				</button>
			</div>
			{
				table.emptySearch || table.emptyTable ? (
						table.emptySearch ? <NoResultsSearch limit={table.pagination.limit} resetFunc={resetFunc}/>
							: <TableEmpty 
							handler={checkUserPermission('assessment','add') ? emptyTableCallback: ()=>navigate("/")} 
							cta={checkUserPermission('assessment','add') ? 'Create new domain assessment':'Go to Dashboard'} 
							title={'Table is empty'}
							desc={'Domain assessment table is empty'}/>
					)
					:
					<>
				<AuthGuard module='assessment' permission='view'>
				<table className='table' ref={tableRef} onScroll={(e: any) => {
							if (e.target.scrollLeft > 10 && !horizontalScrollState) setHorizontalScrollState(() => true)
							else if (e.target.scrollLeft < 10 && horizontalScrollState) setHorizontalScrollState(() => false)
						}}>
							<thead className="table__thead">
							<td
								className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} table__td--thead jobs-table__column__middle`}>
								<TheadItemComponent title={table.fixedTab.displayName} handler={null} dbName={''}/>
							</td>
							{
								table.tabFilter.map((item) => {
									if(item.displayName === 'Actions' && !checkUserPermission('assessment',['edit','delete'])){
										return
									}
									return item.active &&
                      <td className={`table__td  table__td--thead jobs-table__column__default`}
                          key={item.id}>
                          <TheadItemComponent title={item.displayName} handler={item.dbName ? tdOrderCallback : null}
                                              dbName={item.dbName}/>
                      </td>
								})
							}

							</thead>
							 <ManageDomainAssessmentTBody horizontalScrollState={horizontalScrollState}/>
						</table>

						<div className='table-mobile'>
							{
								table.tableItems.map(item => {
									return <ManageDomainMobileTable key={item.id} item={item}/>
								})
							}
						</div> 
						<PaginationComponent
							limit={table.pagination.limit}
							currentPage={table.pagination.currentPage}
							totalCount={table.pagination.totalCount}
							setCurrentPage={setDomainAssessmentScreenHandler}
							setLimit={setLimitHandler}
							filters={table.filters}
						/>
				</AuthGuard>
				</>
			}
		</div>
	</section>
}
export default memo(DomainAssessmentScreen)

export const setDomainAssessmentScreenHandler = (page: number, limit: number, filters: any) => {
	store.dispatch(setCurrentPageDomainAssessment(page))
	const token: any = localStorage.getItem('token')
	const config = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    },
}
	const getData = async () => {
		await axios(`${API_ASSESSMENT}/api/domain-questions?limit=${limit}
		&search=${encodeURIComponent(filters.searchValue)}
		${filters.score.map(item => `&score=${item}`).join('')}
		${filters.industry.map(item => `&industryId=${item.id}`).join('')}
		${filters.department.map(item => `&departmentId=${item.id}`).join('')}
		${filters.time.map(item => `&time=${item}`).join('')}
		&offset=${(page - 1) * limit}
		&sortType=${filters.sortType}
		&sortBy=${filters.sortBy}`, config).then(res => {
			store.dispatch(setTotalCountDomainAssessment(res.data.count))
			const items = transformArrayToTableData(res.data.rows)
			store.dispatch(setTableItemsDomainAssessment(items))
			if (items.length === 0) {
				if (filters.searchValue === '' &&
					filters.industry.length===0 && filters.department.length === 0 )
				{
					store.dispatch(setEmptyTableDomainAssessment(true))
				} else {
					store.dispatch(setEmptySearchDomainAssessment(true))
				}
			} else {
				store.dispatch(setEmptySearchDomainAssessment(false))
				store.dispatch(setEmptyTableDomainAssessment(false))
			}
		})
	}
	getData().then()
}
const setLimitHandler = (value: any) => {
	store.dispatch(setLimitDomainAssessment(value))
}
const transformArrayToTableData = (array: any): Array<any> => {
	return array.map(item => {
		return {
			id: item.id,
			assessmentName: item?.name,
			industryId:item?.industryId,
			department: item?.departmentId,
			timeDuration: item?.duration,
			score: item?.passing,
			instruction: item?.instruction,
		}
	})
}
const resetFunc = (pagination) => {
	store.dispatch(setIndustryDomainAssessment([]))
	store.dispatch(setDepartmentDomainAssessment([]))
	store.dispatch(setScoreFilterDomainAssessment([0]))
	store.dispatch(setTimeFilterDomainAssessment([]))
	store.dispatch(setSearchValueDomainAssessment(''))
	store.dispatch(setSortByDomainAssessment('id'))
	store.dispatch(setSortTypeDomainAssessment('DESC'))
	setDomainAssessmentScreenHandler(1, pagination, defaultFiltersDomainAssessmentScreen)
}