  export enum DrugCandidatesTableHeader{ 
    CANDIDATE_NAME = "Canidate Name",
    CANDIDATE_PHONE_NUMBER = "Candidate Phone Number",
    THIRD_PARTY_VENDOR = "3rd Party Vendor",
    DATE_DS_COMPLETED = "Date Drug Screening Completed",
    RECRUITER_COMMENTS = "Recruiter Comments",
    CANDIDATE_SCORE_BOARD = "Candidate Score Board",
    CANDIDATE_DS_STATUS = "Candidate DS Status",
    ACTIONS = "Actions"
  }

  export enum DrugCandidatesFilterTabs{
    DATE_RANGE_OF_DS_COMPLETED = "Date Range of DS Completed",
    CANDIDATE_DS_STATUS = "Candidate DS Status",
  }
  
  export enum DSStatus {
    NOT_INITIATED = "Not Initiated",
    IN_PROGRESS = "In Progress",
    REPORT_ON_HOLD = "Report On Hold",
    READY_FOR_REVIEW = "Ready For Review",
    VERIFIED = "Verified",
    REJECTED = "Rejected",
  }
  
  
  