import {memo} from 'react'

const RejectPopupJobs = ({onReject,onCancel}) => {

	return (
		<div className="popup apply-popup">
		<div className="popup__step">
			<div className="popup__head">
				<p className="popup__head__headline">
				Reject Candidate
				</p>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className="popup__head__close"
					onClick={onCancel}
				>
					<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round" />
				</svg>
			</div>
			<div className="popup__body">
				<p className="popup__body__text">
				If you reject candidate, all of the hiring process of this candidate will stop.
				</p>

			</div>
			<div className="popup__bottom end">
				<button
					className="popup__bottom__cancel button--empty"
					onClick={onCancel}
				>
					Cancel
				</button>
				<button
					className="popup__bottom__button button--filled"
					onClick={onReject}
				>
						Reject
				</button>
			</div>
		</div>
	</div>
		
	)
}

export default memo(RejectPopupJobs)