import {memo, useState} from "react";
import SingleItem from "../../Global/table/SingleMobileItem";
import {useNavigate, useParams} from "react-router-dom";
import {store} from "../../../store";
import reject from "../../../image/icon/reject_job_ic.svg";
import {DetailViewJobMatched} from "../../../enums/detail-view-job/detail-view-job-matched";
import defaultAvatar from "../../../image/icon/avatar.svg";
import eye from "../../../image/icon/eye-on.svg";

const MobileTableDetailedView = ({item}) => {
	const [openItem, setOpenItem] = useState(false);
	const navigate = useNavigate();
	const params = useParams();
	return <div className="table-mobile__item">
		<div className={`table-mobile__top ${openItem ? "active" : ""}`} onClick={() => {
			setOpenItem(prev => !prev);
		}}>
			<div className="table-mobile__top__left">
				<img src={item.avatar || defaultAvatar} alt="avatar" className="table-mobile__avatar"/>
				<p
					className="table-mobile__top__name" onClick={()=>{
						navigate(`/recruitment/job/${params.id}/candidate-scoreboard/${item.id}`);
					}}>{`${item.firstname} ${item.middlename ? item.middlename : ""} ${item.lastname}`}</p>
			</div>
			<ArrowSvg/>
		</div>
		<div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
			<SingleItem title={DetailViewJobMatched.PERCENT + ":"} text={item.percent + "%"}
			            textStyle={"table-mobile__item__text"}/>
			<SingleItem title={DetailViewJobMatched.Location + ":"} text={item.location}
			            textStyle={"table-mobile__item__text "}/>
			<SingleItem title={DetailViewJobMatched.Experience + ":"} text={item.experience + " years"}
			            textStyle={"table-mobile__item__text "}/>
			<SingleItem title={DetailViewJobMatched.Salary + ":"} text={item.salary} textStyle={"table-mobile__item__text"}/>
			<SingleItem title={DetailViewJobMatched.Phone + ":"} text={item.phone}
			            textStyle={"table-mobile__item__text table-mobile__item__text--green"}/>
			<SingleItem title={DetailViewJobMatched.Email + ":"} text={item.email}
			            textStyle={"table-mobile__item__text table-mobile__item__text--green"}/>
			<SingleItem title={DetailViewJobMatched.Status + ":"} text={item.status} textStyle={"table-mobile__item__text"}/>
			<SingleItem title={DetailViewJobMatched.Score + ":"} text={item.scores+' %'} textStyle={"table-mobile__item__text"}/>
			<div className="table-mobile__item__body__action">
			{
				item.status ? <>
						<button
							className={`table-mobile__item__action-button table-mobile__item__action-button--green ${item.summary?'table-mobile__item__action-button--half':''}`}
							onClick={() => {
								navigate(`/recruitment/job/${params.id}/candidate-scoreboard/${item.id}`);
							}}>
							<img src={eye} alt="delete-icon"/> Scoreboard
						</button>
						{
							item.summary && (
								<button
									className="table-mobile__item__action-button table-mobile__item__action-button--green table-mobile__item__action-button--half"
									onClick={() => {
										navigate(`/recruitment/job/${params.id}/matched-profiles/${item.id}`);
									}}>
									<SeeApplyIcon/> Profile
								</button>
							)
						}
					</> :
					<button className="table-mobile__item__action-button table-mobile__item__action-button--green"
					        onClick={() => {
						        navigate(`/recruitment/candidate-profile/${item.id}`);
					        }}>
						<img src={eye} alt="delete-icon"/> Information
					</button>
			}
			</div>
		</div>
	</div>;
};
export default memo(MobileTableDetailedView);
const ArrowSvg = () => {
	return <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_6751_20970)">
			<path d="M1 3L5 7L9 3" stroke="#5F6E80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_6751_20970">
				<rect width="10" height="10" fill="white" transform="translate(0 10) rotate(-90)"/>
			</clipPath>
		</defs>
	</svg>;
};

const SeeApplyIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
		     className="manageAssignment__table__icon">
			<path
				d="M11.0007 18.3327H5.00065C4.55862 18.3327 4.1347 18.1571 3.82214 17.8445C3.50958 17.532 3.33398 17.108 3.33398 16.666V3.33268C3.33398 2.89065 3.50958 2.46673 3.82214 2.15417C4.1347 1.84161 4.55862 1.66602 5.00065 1.66602H11.6673L16.6673 6.66602V11.4743"
				stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M11.666 1.66602V6.66602H16.666" stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M8.33268 7.5H7.49935H6.66602" stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			<path
				d="M5.16602 13.834C5.16602 13.834 7.52965 9.33398 11.666 9.33398C15.8024 9.33398 18.166 13.834 18.166 13.834C18.166 13.834 15.8024 18.334 11.666 18.334C7.52965 18.334 5.16602 13.834 5.16602 13.834Z"
				stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			<path
				d="M11.666 15.334C12.4944 15.334 13.166 14.6624 13.166 13.834C13.166 13.0056 12.4944 12.334 11.666 12.334C10.8376 12.334 10.166 13.0056 10.166 13.834C10.166 14.6624 10.8376 15.334 11.666 15.334Z"
				stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>

	);
};

