import type { PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line no-duplicate-imports
import { createSlice } from '@reduxjs/toolkit';
import { IAsyncCheckBoxList, ITabsFilter } from "../../types/global/global";
import { IManageAssignment } from '../../types/redux/manage-assignment';
import { ManageAssignmentFilterTabs, ManageAssignmentTabs } from '../../enums/manage-assignment/manageAssignment';

export const defaultFiltersManageAssignmentScreen = {
	searchValue: '',
	sortBy: 'id',
	sortType: 'DESC',
	assessmentType:[],
	jobId:[],
}
const initialStateManageAssignment:IManageAssignment = {
	emptySearch: false,
	emptyTable: false,
	filters: defaultFiltersManageAssignmentScreen,
	tableItems: [],
	openPreview:{
		isOpen:false,
		title:null
	},
	popupWithInstruction:{
		open:false,
		id:-1,
		data:'',
		title:null,
		ids:null
	},
	popupWithDelete:{
		open:false,
		id:-1,
		title:null,
	},
	fixedTab: {
		id: 1,
		displayName: ManageAssignmentTabs.jobTitle,
	},
	tabFilter: [
		{
			id: 2,
			displayName: ManageAssignmentTabs.candidates,
			active: true,
			dbName: ''
		},
		{
			id: 3,
			displayName: ManageAssignmentTabs.assessmentType,
			active: true,
			dbName: ''
		},
		{
			id: 4,
			displayName: ManageAssignmentTabs.Actions,
			active: true,
			dbName: ''
		},
		
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0
	},
	filterInfo: {
		tabs:
			[
				ManageAssignmentFilterTabs.assessmentType,
				ManageAssignmentFilterTabs.jobId
			],
	}
}

export const manageAssignment = createSlice({
	name: 'manage-assignment',
	initialState: initialStateManageAssignment,
	reducers: {
		setFiltersInfoManageAssignment: (state, action: PayloadAction<any[]>) => {
			state.filterInfo.tabs = action.payload
		},
		setLimitManageAssignment: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload
		},
		setSearchValueManageAssignment: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setOrderManageAssignment: (state, action: PayloadAction<Array<ITabsFilter>>) => {
			state.tabFilter = action.payload
		},
		setSortByManageAssignment: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload
		},
		setSortTypeManageAssignment: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload
		},
		setEmptyTableManageAssignment: (state, action: PayloadAction<boolean>) => {
			state.emptyTable = action.payload
		},
		setEmptySearchManageAssignment: (state, action: PayloadAction<boolean>) => {
			state.emptySearch = action.payload
		},
		setTableItemsManageAssignment: (state, action: PayloadAction<Array<any>>) => {
			state.tableItems = action.payload
		},
		setTotalCountManageAssignment: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload
		},
		setOpenPreview: (state, action: PayloadAction<any>) => {
			state.openPreview = action.payload
		},
		setCurrentPageManageAssignment: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload
		},
		setPopupWithAssessmentType: (state, action: PayloadAction<{open: boolean, id: number, data:any, title:any, ids:any}>) => {
			state.popupWithInstruction = action.payload
		},
		setPopupWithDelete: (state, action: PayloadAction<{open: boolean, id: number, title:any}>) => {
			state.popupWithDelete = action.payload
		},
		setAssessmentTypeManageAssignment: (state, action: PayloadAction<IAsyncCheckBoxList[]>) => {
			state.filters.assessmentType = action.payload
		},
		setJobManageAssignment: (state, action: PayloadAction<IAsyncCheckBoxList[]>) => {
			state.filters.jobId = action.payload
		},
	},
})

export const {	
	setSortTypeManageAssignment,
	setLimitManageAssignment,
	setSortByManageAssignment,
	setOrderManageAssignment,
	setSearchValueManageAssignment,
	setEmptyTableManageAssignment,
	setEmptySearchManageAssignment,
	setTableItemsManageAssignment,
	setTotalCountManageAssignment,
	setCurrentPageManageAssignment,
	setPopupWithAssessmentType,
	setPopupWithDelete,
	setJobManageAssignment,
	setAssessmentTypeManageAssignment,
	setOpenPreview,
	setFiltersInfoManageAssignment
} = manageAssignment.actions

export default manageAssignment.reducer