import { getEnv } from "@urecruits/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { store, useTypedSelector } from "../store";
import { TableCardViewComp, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { IColumns } from "@ucrecruits/globalstyle/types/table-types";
import {
  setCurrentPageScoreboard,
  setJobTitleScoreboard,
  setLimitScoreboard,
  setOrderScoreboardColumns,
  setSearchValueScoreboard,
  setSortByFilterScoreboard,
  setSortTypeFilterScoreboard,
  setTotalCountScoreboard,
  setTotalScoresScoreboard,
  setWorkflowPopupScoreboard,
} from "../store/reducers/scoreboardReducer";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import useClickOutside from "../hook/useClickOutside";
import defaultAvatar from "../image/temp-user.png";
import { findActiveRound } from "../utils";
import { useNavigate } from "react-router-dom";
import Filter from "../components/Scoreboard/Filter";
import WorkflowPopupComponent from '../components/Scoreboard/Popup/WorkflowPopup';

const { API_RECRUITMENT } = getEnv();

const getScoreboardTable = (store) => store.scoreboard;
const getFiltersFunc = (state) => state.scoreboard.filters;
const getFilterDataFunc = (state) => state.scoreboard.filterInfo;

const ScoreboardScreen = () => {
  const navigate = useNavigate();
  const columns: Array<IColumns> = [
    {
      headerName: "Candidate Name",
      field: "candidate",
      pinnable: true,
      renderCell: (cellValue, row) => {
        const user = row.user;
        return (
          <div
            className="scoreboard-table__candidate"
            onClick={() => {
              navigate(
                `/recruitment/job/${row.jobId}/candidate-scoreboard/${row.userId}`
              );
            }}
          >
            <img
              src={
                !!user?.candidate.avatar
                  ? user?.candidate.avatar
                  : defaultAvatar
              }
              alt="avatar"
              className="scoreboard-table__candidate__avatar"
            />
            <p className="scoreboard-table__candidate__text">
              {user?.firstname + " " + user?.lastname}
            </p>
          </div>
        );
      },
      mobileRenderCell: (cellValue, row) => {
        const user = row.user;
        return (
          <div className="scoreboard-table__candidate"  
          onClick={() => {
            navigate(
              `/recruitment/job/${row.jobId}/candidate-scoreboard/${row.userId}`
            );
          }}>
            <img
              src={
                !!user?.candidate.avatar
                  ? user?.candidate.avatar
                  : defaultAvatar
              }
              alt="avatar"
              className="scoreboard-table__candidate__avatar"
            />
            <p className="scoreboard-table__candidate__text">
              {user?.firstname + " " + user?.lastname}
            </p>
          </div>
        );
      },
      visibility: true,
      isMobileTitle: true,
    },
    {
      headerName: "Job Title",
      field: "jobTitle",
      renderCell: (cellValue, row) => {
        return (
          <p className="scoreboard-table__text green">{row.job?.title || ""}</p>
        );
      },
      mobileRenderCell: (cellValue, row) => {
        return (
          <p className="scoreboard-table__text green">{row.job?.title || ""}</p>
        );
      },
      visibility: true,
    },
    {
      headerName: "Phone & Email",
      field: "contact",
      renderCell: (cellValue, row) => {
        return (
          <p className="scoreboard-table__text green">
            {(row.user?.phone || "") + " \n " + (row.user?.email || "")}
          </p>
        );
      },
      mobileRenderCell: (cellValue, row) => {
        return (
          <p className="scoreboard-table__text green">
            {(row.user?.phone || "") + " \n " + (row.user?.email || "")}
          </p>
        );
      },
      visibility: true,
    },
    {
      headerName: "Total scores",
      field: "totalScores",
      renderCell: (cellValue, row) => {
        return `${cellValue}%`;
      },
      mobileRenderCell: (cellValue, row) => {
        return `${cellValue}%`;
      },
      visibility: true,
      sort:true
    },
    {
      headerName: "Workflow",
      field: "workflow",
      renderCell: (cellValue, row) => {
        const workflows = row.job?.workflow?.workflow;
        return (
          <div className="workflow-field">
            contains {workflows.length} steps
            <p className="scoreboard-table__text green" style={{cursor:'pointer'}} onClick={()=>{
              store.dispatch(setWorkflowPopupScoreboard({open:true,title:row.job?.workflow?.title,workflows:row.job?.workflow?.workflow}))
            }}>
              {workflows.length > 0 ? "See more" : "N/A"}
            </p>
          </div>
        );
      },
      mobileRenderCell: (cellValue, row) => {
        const workflows = row.job?.workflow?.workflow;
        return (
          <div className="workflow-field">
            contains {workflows.length} steps
            <p className="scoreboard-table__text green" style={{cursor:'pointer'}} onClick={()=>{
              store.dispatch(setWorkflowPopupScoreboard({open:true,title:row.job?.workflow?.title,workflows:row.job?.workflow?.workflow}))
            }}>
              {workflows.length > 0 ? "See more" : "N/A"}
            </p>
          </div>
        );
      },
      visibility: true,
    },
    {
      headerName: "Userflow Status",
      field: "userflowStatus",
      renderCell: (cellValue, row) => {
        const data = findActiveRound(row.round?.rounds);
        return <p className={`table__status-block table__status-block--${data?.status === 'Completed' ? 'green': data.status === 'In Progress' ? 'orange' : 'yellow'}`}>{data.title}</p>;
      },
      mobileRenderCell: (cellValue, row) => {
        const data = findActiveRound(row.round?.rounds);
        return <p className={`table__status-block table__status-block--${data?.status === 'Completed' ? 'green': data.status === 'In Progress' ? 'orange' : 'yellow'}`}>{data.title}</p>;
      },
      visibility: true,
    },
  ];
  const [isFilterSubmit, setIsFilterSubmit] = useState<boolean>(false);
  const [isEmptyFilter, setIsEmptyFilter] = useState<boolean>(false);
  const [orderPopup, setOrderPopup] = useState(false);

  const orderPopupRef = useRef<null | HTMLButtonElement>(null);
  const tableRef = useRef(null);
  const table = useTypedSelector(getScoreboardTable);

  const [filterString, setFilterString] = useState<string>("");

  useEffect(() => {
    const filters = table.filters;
    if (isFilterSubmit && filters) {
      setFilterString(
        `${
          filters.jobId
            ? filters.jobId.map((job) => "&jobId=" + job.value).join("")
            : ""
        }${
          filters.totalScore.length > 0
            ? "&totalScoreMin="+filters.totalScore[0]+"&totalScoreMax="+filters.totalScore[1]
            : ""
        }`
      );
    }
  }, [isFilterSubmit]);

  const createAPIString = () => {
    const pagination = table.pagination;
    const filters = table.filters;

    return `${API_RECRUITMENT}/api/subscribe/scoreboard?limit=${
      pagination.limit
    }&offset=${(pagination.currentPage - 1) * pagination.limit}
		${filters.searchValue ? "&search=" + filters.searchValue : ""}${filterString}${
      filters.sortBy ? "&sortBy=" + filters.sortBy : ""
    }${filters.sortType ? "&sortType=" + filters.sortType : ""}  `;
  };

  useTableClickAndDragScroll(tableRef);
  useClickOutside(orderPopupRef, setOrderPopup);

  const resetFilters = () => {
    store.dispatch(setSortByFilterScoreboard("id"));
    store.dispatch(setSortTypeFilterScoreboard("ASC"));
    store.dispatch(setSearchValueScoreboard(""));
    store.dispatch(setJobTitleScoreboard(0));
    store.dispatch(setTotalScoresScoreboard([0,100]))
    setIsFilterSubmit(true);
  };

  const setSort = (field) => {
    store.dispatch(setSortByFilterScoreboard(field));
    const asc_desc =
      table.filters.sortBy === field && table.filters.sortType === "ASC"
        ? "DESC"
        : "ASC";
    store.dispatch(setSortTypeFilterScoreboard(asc_desc));
  };

  const setSearchCallback = useCallback(
    function setSerachValue(value: string) {
      store.dispatch(setSearchValueScoreboard(value));
      store.dispatch(setCurrentPageScoreboard(1));
    },
    [table.filters]
  );

  return (
    <><AuthGuard module='job-post' permission='view'>
    <section className="scoreboard">
      {
        table.workflowPopup.open && <WorkflowPopupComponent />
      }
      <div className="scoreboard__head">
        <h2>Scoreboard</h2>
      </div>
      <TableCardViewComp
        columns={columns}
        listAPI={createAPIString()}
        sort={(field) => setSort(field)}
        searchField={{
          searchValue: table.filters.searchValue,
          setSearchValue: setSearchCallback,
          placeholder: "Enter candidate name",
        }}
        pagination={{
          limit: table.pagination.limit,
          currentPage: table.pagination.currentPage,
          totalCount: table.pagination.totalCount,
          setTotalCount: (count: number) => {
            store.dispatch(setTotalCountScoreboard(count));
          },
          setCurrentPage: (value: number) =>
            store.dispatch(setCurrentPageScoreboard(value)),
          setLimit: (limit: number) =>
            store.dispatch(setLimitScoreboard(limit)),
        }}
        rearrangeColumns={{ setColumnOrder: setOrderScoreboardColumns }}
        storeName={"scoreboard"}
        store={store}
        useTypedSelector={useTypedSelector}
        filters={{
          filterData: useTypedSelector(getFilterDataFunc),
          filter: useTypedSelector(getFiltersFunc),
          resetFilters,
          filterItems: (state) => <Filter filterType={state} />,
          isFilterSubmit,
          setIsFilterSubmit: (value: boolean) => setIsFilterSubmit(value),
          isEmptyFilter,
          setIsEmptyFilter: (value: boolean) => setIsEmptyFilter(value),
        }}
      />
    </section>
    </AuthGuard></>
  );
};

export default ScoreboardScreen;