import {isInterview,isRoundNotCompleted} from "./checks";
export const findActiveRound = (rounds)=>{
    let lastRound;

    for(const round of rounds){
      if(isRoundNotCompleted(round))
        return round;
      lastRound = round;
    }
    return lastRound;
}

export const findActiveInterview = (rounds) => {
  let lastInterview;
  
  for(const round of rounds){
    if (isInterview(round) && isRoundNotCompleted(round)) 
          return round;
    lastInterview = round;
  }
   
  return lastInterview;
};


