import { useNavigate, useParams } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import ManageTeamPopup from "../ManageTeamMembers/Popup/ManageTeamPopup";
import { getEnv } from "@urecruits/api";
import fetchData from "../../hook/http";
import { useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const {API_RECRUITMENT}=getEnv()

const setData = (WrappedComponent, setDefaultData) => {
	return function (props) {
		const {checkUserPermission} = useHasPermission()
		const location = useParams();
		const navigate=useNavigate()
		const [initData, setInitData] = useState<any>('');
		useLayoutEffect(() => {
			checkUserPermission('team-members','edit') && setDefaultData(location.id).then(res=>{
				if(!res||res.user.roles.find(x=>x.label==='Company Owner')) navigate("/recruitment/manage-team-members")
				setInitData({
					initialFirstName:res.user.firstname,
					initialLastName:res.user.lastname,
					initialOfficeEmail:res.officeEmail,
					initialOfficePhone:res.officePhone,
					initialJobTitle:res.position,
					initialDepartment:res.department,
					initialJobLocation:{
						value: `${res.location.id}`,
						label: `${res.location.city}, ${res.location.state}`,
					},
					initialEducational:res.degree,
					initialDateOfJoining:res.dateJoining,
					initialBirthday:res.birthday,
					initialStreet:res.currentStreet,
					initialCity:res.currentCity,
					initialState:res.currentState,
					initialCountry:res.currentCountry,
					initialZip:res.currentZip,
					initialManager:res.reportingId,
					initialImageLink:res.user.avatar,
					initialRole:res.user.roles,
					userId:res.user.id,
					recruiterId:res.id
				})
			})
		}, [])

		return <WrappedComponent {...props} initData={initData}/>
	}
}

export const EditMember= setData(ManageTeamPopup,  (location) => {
		return fetchData(`${API_RECRUITMENT}/api/company/member/${location}`).then()
})
