import { store, useTypedSelector } from "../../../store";
import { Link, useNavigate } from "react-router-dom";
import { memo } from "react";
import { JobStatus } from "../../../enums/jobs/jobsEnums";
import editIcon from "../../../image/icon/edit_ic.svg";
import deleteIcon from "../../../image/icon/delete_ic.svg";
import match from "../../../image/icon/match.svg";
import approve from "../../../image/icon/approve_job_ic.svg";
import reject from "../../../image/icon/reject_job_ic.svg";
import { IJobsTableItem } from "../../../types/redux/jobs";
import { setApprovePopupJobs, setDeletePopupJobs, setRejectPopupJobs } from "../../../store/reducers/jobsTableReducer";
import SelectStatus from "./SelectStatus";
import AsyncWorkflows from "./ReactSelectWorkflowsJobTable";
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const JobsFuncItems = state => state.jobs.tableItems
const JobsFuncTab = state => state.jobs.tabFilter
const JobsTBody = ({horizontalScrollState}: { horizontalScrollState: boolean }) => {

	const {checkUserPermission} = useHasPermission()
	const tableItems = useTypedSelector(JobsFuncItems)
	const tableTabs = useTypedSelector(JobsFuncTab)

	return <tbody className="table__tbody">
	{
		tableItems.map(item => {
			return <tr className="table__tr" key={item.id}>
				<td className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} jobs-table__column__middle`}>
					{
						item.status === "publish" ? <Link to={`/recruitment/job/${item.id}/matched-profiles`}
						                                  className="manage-team__text manage-team__text--link jobs-table__text__capitalize ">{item.jobTitle}</Link>
							: <p className="manage-team__text manage-team__text--green jobs-table__text__capitalize ">{item.jobTitle}</p>
					}
				</td>
				{
					tableTabs.map(value => {
						if (value.id === 2) {
							return value.active &&
                  <td className="table__td actions jobs-table__column__middle" key={value.displayName}>
                      <div className='jobs-table__locations'>
                          <p className='manage-team__text manage-team__text--gray7  jobs-table__text__no-overflow'>{item.jobLocation}</p>
												{
													item.remoteLocations &&
                          <p className='manage-team__text manage-team__text--gray5 jobs-table__locations__remote'>(Remote
                              location available)</p>
												}
                      </div>
                  </td>
						}
						if (value.id === 3) {
							return value.active &&
                  <td className="table__td actions jobs-table__column__default" key={value.displayName}>
                      <p className='manage-team__text manage-team__text--gray7 jobs-table__text__salary'>{item.salary}<span
                          className='manage-team__text manage-team__text--gray5'> (per year)</span></p>
                  </td>
						}
						if (value.id === 4) {
							return value.active &&
                  <td className="table__td actions jobs-table__column__default" key={value.displayName}>
                      <p className='manage-team__text manage-team__text--gray5'>{item.postedOn}</p>
                  </td>
						}
						if (value.id === 5) {
							return value.active &&
                  <td className="table__td actions jobs-table__column__default" key={value.displayName}>
                      <p className='manage-team__text manage-team__text--gray5'>{item.postedBy}</p>
                  </td>
						}
						if (value.id === 6) {
							return value.active &&
                  <td className="table__td actions jobs-table__column__default" key={value.displayName}>
										{
											item.status === 'publish' &&
												<>
												{
												checkUserPermission('job-post','edit') ? 
														<SelectStatus status={'publish'} id={item.id}/>
														:
														<div className='table-dropdown-with-color-btn'>
																<div className={'table-dropdown-with-color-btn__wrap'}>
																		<div
																				className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
																				<p className='table__status-block--green'>{JobStatus.PUBLISH}</p>
																		</div>
																</div>
														</div>
												}
												</>
										}
										{
											item.status === 'approval' &&
                      <div className='table-dropdown-with-color-btn'>
                          <div className={'table-dropdown-with-color-btn__wrap'}>
                              <div
                                  className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
                                  <p className='table__status-block--yellow'>{JobStatus.Pending}</p>
                              </div>
                          </div>
                      </div>
										}
										{
											item.status === 'draft' &&
                      <div className='table-dropdown-with-color-btn'>
                          <div className={'table-dropdown-with-color-btn__wrap'}>
                              <div
                                  className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
                                  <p className='table__status-block--red'>{JobStatus.DRAFT}</p>
                              </div>
                          </div>
                      </div>
										}
										{
											item.status === 'rejected' &&
                      <div className='table-dropdown-with-color-btn'>
                          <div className={'table-dropdown-with-color-btn__wrap'}>
                              <div
                                  className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
                                  <p className='table__status-block--red'>{JobStatus.REJECTED}</p>
                              </div>
                          </div>
                      </div>
										}
										{
											item.status === 'closed' &&
											<>
                      {
												checkUserPermission('job-post','edit') ?
												<SelectStatus status={'closed'} id={item.id}/>
												:
												<div className='table-dropdown-with-color-btn'>
                          <div className={'table-dropdown-with-color-btn__wrap'}>
                              <div
                                  className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
                                  <p className='table__status-block--gray'>{JobStatus.CLOSED}</p>
                              </div>
                          </div>
                      </div>
											}
											</>
										}
										{
											item.status === 'onhold' &&
											<>
											{
												checkUserPermission('job-post','edit') ?
												<SelectStatus status={'onhold'} id={item.id}/>
												:
												<div className='table-dropdown-with-color-btn'>
                          <div className={'table-dropdown-with-color-btn__wrap'}>
                              <div
                                  className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
                                  <p className='table__status-block--orange'>{JobStatus.ON_HOLD}</p>
                              </div>
                          </div>
                      </div>
											}
											</>
										}
                  </td>
						}
						if (value.id === 7) {
							return value.active &&
                  <td className="table__td actions jobs-table__column__default" key={value.displayName}>
												{
												checkUserPermission('job-post','edit') && item.status !== 'closed'?
													<div className='jobs-table__async-wrap'>
															<AsyncWorkflows item={item}/>
													</div>
													:
													<div className="manage-team__text">
													{item?.positionWorkflow?.label || ""}
													</div>
											}
                  </td>
						}
						if (value.id === 8) {
							return value.active &&
                  <td className="table__td actions jobs-table__column__small" key={value.displayName}>
										{
											(item.status === 'publish' || item.status === 'onhold') && <MemoPublish item={item} />
										}
										{
											(item.status === 'rejected' || item.status === 'closed' || item.status === 'draft') &&
                      <MemoClosed item={item}/>
										}
										{
											item.status === 'approval' && <MemoPending item={item}/>
										}
                  </td>
						}
					})
				}
			</tr>
		})
	}
	</tbody>
}
export default memo(JobsTBody)

const MemoPublish = memo(({item}: { item: IJobsTableItem}) => {
	const navigate = useNavigate()
	return <>
          <AuthGuard module='job-post' permission='edit'>
					<img src={editIcon} alt="edit-icon" className="manageAssignment__table__icon" onClick={()=>navigate(`/job/${item.id}/edit/`)}/>
					</AuthGuard>
					<AuthGuard module='job-post' permission='delete'>
          <img src={deleteIcon} alt="delete-icon" className="manageAssignment__table__icon"
               onClick={(e) => {
						     store.dispatch(setDeletePopupJobs({
							     open: true,
							     name: item.jobTitle,
							     id: item.id
						     }))
					     }}
          />
					</AuthGuard>
					<AuthGuard module='job-post' permission='view'>
					<img src={match} alt="match-profile-icon" className="manageAssignment__table__icon" onClick={() => navigate(`/recruitment/job/${item.id}/matched-profiles`)}/>
					</AuthGuard>
	</>
})

const MemoClosed = memo(({item}: { item: IJobsTableItem}) => {
	const navigate = useNavigate()
	return <>
          <AuthGuard module='job-post' permission='edit'>
          <img src={editIcon} alt="edit-icon" className="manageAssignment__table__icon" onClick={()=> navigate(`/job/${item.id}/edit/`)}/>
					</AuthGuard>
          <AuthGuard module='job-post' permission='delete'>
          <img src={deleteIcon} alt="delete-icon" className="manageAssignment__table__icon"
               onClick={(e) => {
						     store.dispatch(setDeletePopupJobs({
							     open: true,
							     name: item.jobTitle,
							     id: item.id
						     }))
					     }}
          />
					</AuthGuard>
			</>
})
const MemoPending = memo(({item}: { item: IJobsTableItem}) => {
	const navigate = useNavigate()
	return <>
          <AuthGuard module='job-post' permission='edit'>
          <img src={editIcon} alt="edit-icon" className="manageAssignment__table__icon" onClick={()=> navigate(`/job/${item.id}/edit/`)}/>
          <img src={approve} alt="delete-icon" className="manageAssignment__table__icon" onClick={() => {
						store.dispatch(setApprovePopupJobs({open: true, name: item.jobTitle, id: item.id}))
					}}/>
          <img src={reject} alt="delete-icon" className="manageAssignment__table__icon" onClick={async () => {
						store.dispatch(setRejectPopupJobs({open: true, name: item.jobTitle, id: item.id}))
					}}/>
					</AuthGuard>
			</>
})



