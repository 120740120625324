import {memo, useRef, useState} from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import {store, useTypedSelector} from "../../store";
import candidateScoreboard, {
	setCalendarPopupCandidateScoreboard,
	setUpdateRoundPopupCandidateScoreboard
} from "../../store/reducers/candidateScoreboardScreenReducer";
const date=state=>state.candidate_scoreboard.calendarPopup
const CalendarPopup = () => {
	const {open,startDate,endDate}=useTypedSelector(date)
	const [start, setStart] = useState(startDate);
	const [end, setEnd] = useState(endDate);
	const ref = useRef();

	return <div className='calendar-popup'>
		<div className='calendar-popup__inner' ref={ref}>
			<FullCalendar
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
				initialView="timeGridWeek"
				selectable
				height='calc(100% - 60px)'
				dayCellClassNames={returnClassname}
				dayHeaderClassNames ={returnClassname}
				slotLabelInterval={'00:30'}
				slotLaneClassNames={()=>{
					return ['calendar-popup__lane']
				}}
				slotLabelClassNames={()=>{
					return ['calendar-popup__lane-label']
				}}
				selectAllow={(select) => {
					const now = Date.now()
					return select.end.getTime() > now && select.start.getTime() > now
				}}
				select={(info) => {
					/*alert('select'+ info.startStr)*/
					setStart(info.start)
					setEnd(info.end)
				}
				}
				views={{
					timeGridWeek: {
						allDaySlot: false
					},
					timeGridDay: {
						allDaySlot: false
					}
				}}
				headerToolbar={{
					start: 'prev,next', // will normally be on the left. if RTL, will be on the right
					center: '',
					end: 'title'
				}}
			/>
			<div className="popup__bottom end">
				<button
					type='button'
					className="popup__cancel"
					onClick={()=>store.dispatch(setCalendarPopupCandidateScoreboard({open:false,
						startDate:startDate,
						endDate:endDate
					}))}
				>
					Cancel
				</button>
				<button
					type='button'
					className="popup__bottom__publish button--filled"
					onClick={()=>store.dispatch(setCalendarPopupCandidateScoreboard({open:false,
						startDate:start,
						endDate:end
					}))}
				>
					Confirm Date
				</button>
			</div>
		</div>
	</div>
}
export default memo(CalendarPopup)

const returnClassname = (args) => {
	if (args.isToday) return ['calendar-popup__now calendar-popup__lane']
	if (args.isPast) return ['calendar-popup__past calendar-popup__lane']
	return ['calendar-popup__lane']
}